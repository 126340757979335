import { Box, Button, Divider, Flex, Modal, NumberInput, Stack, Text, Title, Tooltip } from "@mantine/core";
import { useState } from "react";
import { auth, functions } from "../../../App";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import CancelOrganizationSubscription from "./cancel-organization-subscription";
import { useNavigate } from "react-router-dom";

const EditSubscription = ({ organizationId, organizationData }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false, { onClose: () => setNumberOfUsers(organizationData.numberOfUserLicences ?? undefined) });
    const [numberOfUsers, setNumberOfUsers] = useState(organizationData.numberOfUserLicences ?? undefined);
    const [loading, setLoading] = useState(false);

    const lowerLimit = organizationData.members.length > 5 ? organizationData.members.length : 5;

    const userId = auth.currentUser.uid;

    const modifySubscriptionQuantity = async () => {
        setLoading(true);

        if (numberOfUsers < lowerLimit || numberOfUsers > 55) return;

        try {
            // Reference the callable Cloud Function
            const modifySubscription = functions.httpsCallable('modifySubscriptionQuantity');

            // Call the function with necessary data
            const result = await modifySubscription({ organizationId, newQuantity: numberOfUsers });

            console.log('Subscription quantity updated successfully:', result.data);
        } catch (error) {
            console.error('Error modifying subscription quantity:', error);
        }

        setLoading(false);
        close();

        window.location.href = `${window.location.origin}/organization`;
    };

    const pricePerUser = Number.parseInt(t("upgrade.schools.price-per-user")) ?? 35;


    return (
        <>
            <Button color="dark" variant="outline" onClick={open}>{t('organization.subscription-settings.button')}</Button>

            <Modal
                opened={opened}
                onClose={close}
                centered
                title={<Title fw={600} order={5}>{t('organization.subscription-settings.button')}</Title>}
            >

                <Stack spacing={"0.5rem"} mt={"1rem"} mb={"2rem"}>
                    <label>{t('organization.subscription-settings.label')}</label>
                    <NumberInput
                        size="md"
                        maw={"15rem"}
                        value={numberOfUsers}
                        onChange={setNumberOfUsers}
                        min={lowerLimit}
                        max={55}
                        step={1}
                        onBlur={() => { !numberOfUsers && setNumberOfUsers(organizationData.numberOfUserLicences ?? undefined) }}
                    />

                    {organizationData.members.length > 5 && organizationData.members.length === numberOfUsers &&
                        <Text mt={"1rem"} fz={13} color="grey">
                            {t('organization.subscription-settings.minimum-explaination')}
                        </Text>
                    }
                    <Text mt={"1rem"} fz={13} color="grey">{t('organization.subscription-settings.note')}</Text>
                </Stack>

                <Stack spacing={"0rem"} my={"1rem"}>
                    <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.schools.total-price", { price: pricePerUser * numberOfUsers })}</Text>
                    <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.schools.billing-frequency-total")}</Text>
                </Stack>

                <Flex columnGap={"0.5rem"} mt={"1rem"}>
                    <Button onClick={close} variant="outline" color="dark">{t("common.cancel")}</Button>

                    <Button color="dark" loading={loading} onClick={() => modifySubscriptionQuantity()} disabled={!numberOfUsers || numberOfUsers === organizationData.numberOfUserLicences}>{t("common.save")}</Button>
                </Flex>

                <Divider mt={"1rem"} mb={"2rem"} />
                

                <Flex direction={{ base: "column", md: "row" }} columnGap={"0.25rem"} rowGap={"0rem"}>
                    {organizationData.paymentAdministratorId === userId ?
                        <Button mb={"1rem"} variant="light" onClick={() => window.location.href = "https://billing.stripe.com/p/login/9AQdTc2OY5eIdz29AA"}>
                            {t('organization.subscription-settings.invoices')}
                        </Button>
                        :
                        <Tooltip label={t('organization.subscription-settings.invoices-missing-permission')}>
                            <Box>
                                <Button mb={"1rem"} disabled variant="light">
                                    {t('organization.subscription-settings.invoices')}
                                </Button>
                            </Box>
                        </Tooltip>
                    }
                    <br />

                    <CancelOrganizationSubscription organizationId={organizationId} closeSettingsModal={close} />
                </Flex>
            </Modal>
        </>
    )
}

export default EditSubscription;
