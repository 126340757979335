import style from './highlights.module.css';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Highlights = ({ scan, highlightIndex }) => {
    const { t } = useTranslation(['common']);

    const preRef = useRef(null);
    const [elements, setElements] = useState([]);

    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }

    const actualHighlightIndex = scan?.customHighlights?.findIndex(object => object.id === highlightIndex);

    const highlightedWords = [];
    removeDuplicates(scan.customHighlights[actualHighlightIndex].highlightedWords).forEach((highlight) => {
        highlightedWords.push(highlight.toLowerCase())
    })

    highlightedWords.sort((a, b) => b.length - a.length); //Sort in decending order from longest to shortest string in order to avoid a shorter and worst match taking the place of a larger one 

    useEffect(() => {
        const originalText = scan.text;
        let terms = [];
        let coveredIndices = new Set(); // Keep track of covered indices to avoid overlaps

        highlightedWords.forEach(technicalTerm => {
            const regex = new RegExp(`\\b${technicalTerm}\\b`, 'gi');
            let match;
            while ((match = regex.exec(originalText)) !== null) {
                const matchStartIndex = match.index;
                const matchEndIndex = matchStartIndex + technicalTerm.length;
                let isOverlapping = false;

                // Check if any part of the current match is already covered
                for (let i = matchStartIndex; i < matchEndIndex; i++) {
                    if (coveredIndices.has(i)) {
                        isOverlapping = true;
                        break;
                    }
                }

                if (!isOverlapping) {
                    // If not overlapping, add to terms and mark indices as covered
                    terms.push({
                        term: technicalTerm,
                        isTechnicalTerm: true,
                        startIndex: matchStartIndex,
                        endIndex: matchEndIndex
                    });
                    for (let i = matchStartIndex; i < matchEndIndex; i++) {
                        coveredIndices.add(i);
                    }
                }
            }
        });


        // Sort comments by their 'startIndex'
        terms = terms.sort((a, b) => a.startIndex - b.startIndex);

        let currentOffset = 0;
        const elements = terms.reduce((acc, term, index) => {

            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, term.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment or technical term
            acc.push(
                <mark
                    key={term.startIndex}
                    data-comment={term.term}
                    data-index={index}
                    className={style.mark}
                >
                    {originalText.slice(term.startIndex, term.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = term.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
    }, [highlightIndex]);


    return (
        <div className={style.wrapper}>
            <div
                id="text-container"
                className={style.text}
                ref={preRef}
                style={{ whiteSpace: 'pre-line' }}
            >
                {elements}
            </div>
            <div></div>
        </div>
    )
}

export default Highlights;
