import { Flex, Loader, Modal, Text } from "@mantine/core"

const PricingLoader = ({opened, text}) => {

    return(
        <Modal centered opened={opened} withCloseButton={false} closeOnClickOutside={false}>
            <Flex columnGap={"1rem"}>
                <Loader size={"1.5rem"} color="dark" />
                <Text>
                    {text}
                </Text>
            </Flex>
        </Modal>
    )
}

export default PricingLoader;
