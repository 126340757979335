import { useEffect, useState } from 'react';
import style from './page.module.css'

const Page = (props) => {
    const [opacity, setOpacity] = useState(0);

    const styles = {
        fadeIn: {
            transition: 'opacity 0.15s ease-in',
            opacity: opacity,
        },
    }

    useEffect(() => {
        setOpacity(1);
    }, []);

    return(
        <div className={`${style.wrapper} ${props.grey ? style.grey : ""}`} style={styles.fadeIn}>
            <div className={style.content}>
                { props.children }
            </div>
        </div>
    )
}

export default Page;