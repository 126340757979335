import { useState } from "react";

import downloadIcon from '../../assets/download.png'
import { useTranslation } from "react-i18next";

const { Button, Tooltip } = require("@mantine/core");

const ExportScan = ({ scan, firebase, firestore, contentScanId }) => {
    const { t } = useTranslation(['common']);

    const [loading, setLoading] = useState(false)

    function downloadWordDocument(text, comments, title, generalFeedback) {
        setLoading(true);

        const createWordDocument = firebase.app().functions("europe-west1").httpsCallable('generateWordDocument');

        createWordDocument({ text, comments, title, generalFeedback })
            .then(result => {
                const docBase64 = result.data.docBase64;
                const docBlob = base64ToBlob(docBase64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                const blobURL = URL.createObjectURL(docBlob);
                triggerDownload(blobURL, `${convertTitleToFileName(title)}.docx`);

                updateDownloadStatus().then(res => {
                    setLoading(false);
                }).catch(err => {
                    console.error("Error updating hasBeenDownloaded status:", err);

                    setLoading(false);
                });
            })
            .catch(error => {
                console.error("Error getting word document export:", error);

                setLoading(false);
            });
    }

    // Helper function to convert Base64 string to Blob
    function base64ToBlob(base64, type = 'application/octet-stream') {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.prototype.map.call(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type });
    }

    // Helper function to trigger download
    function triggerDownload(blobURL, filename) {
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = filename;
        link.click();
        setTimeout(() => {
            // Remove the link and revoke the object URL after triggering download
            link.remove();
            URL.revokeObjectURL(blobURL);
        }, 100);
    }

    function convertTitleToFileName(title) {
        // Replace special characters with an underscore or another preferred character
        const safeTitle = title.replace(/[\/?<>\\:*|"]/g, '_');

        // truncate the length to a maximum (e.g., 255 characters)
        const truncatedTitle = safeTitle.substring(0, 255);

        // convert to lowercase
        const lowerCaseTitle = truncatedTitle.toLowerCase();

        return lowerCaseTitle;
    }

    const updateDownloadStatus = async () => {
        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            hasBeenDownloaded: true,
        })
    }


    return (
        <Button
            leftIcon={<img style={{ height: "24px", width: "auto" }} src={downloadIcon} alt=""></img>}
            className="plausible-event-name=exportAssessment"
            onClick={() => downloadWordDocument(scan.text, scan.comments, scan.name, scan.generalFeedback)}
            disabled={!scan || !scan.text}
            loading={loading}
            variant="outline"
            color="dark"
        >
            {t('scan.export')}
        </Button>
    );
}

export default ExportScan;

// <Tooltip withArrow label={t('scan.export-description')}>
