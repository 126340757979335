import { useState } from "react"
import Page from "../../molecules/page/page"
import { Button, Flex, Text } from '@mantine/core';

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const JoinOrganization = ({ firebase }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [errorJoinOrganizartion, setErrorJoinOrganization] = useState(null);


    const name = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const id = window.location.href.split("/")[window.location.href.split("/").length - 2]

    const functions = firebase.app().functions("europe-west1");
    const joinOrganization = functions.httpsCallable("joinOrganization")

    const joinOrganizationHandler = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true);

        try {
            const result = await joinOrganization({ organizationInvitationId: id });
            //const data = result.data;
            setLoading(false);
            window.location.href = `${window.location.origin}/`;
        } catch (error) {
            setLoading(false);
            setErrorJoinOrganization(error.message)
        }

        setLoading(false);
    }

    return (
        <Page>
            {errorJoinOrganizartion === "no-available-user-licences" ?
                <div style={{ maxWidth: "30rem", margin: "auto" }}>
                    <h4>
                        {t('join-organization.form.title')}<span style={{ fontWeight: "600" }}>{name.replace(/%20/g, ' ')}</span>
                    </h4>
                    <Text>{t("organization.invite.no-available-user-licences-error")}</Text>
                    <Button w={"8rem"} mt={"1rem"} color={"dark"} onClick={() => navigate("/")}>{t("common.cancel")}</Button>
                </div>
                :
                <div style={{ maxWidth: "30rem", margin: "auto" }}>
                    <h4>
                        {t('join-organization.form.title')}<span style={{ fontWeight: "600" }}>{name.replace(/%20/g, ' ')}</span>
                    </h4>
                    <Flex columnGap={"0.5rem"}>
                        <Button variant="outline" color="dark" onClick={() => navigate("/")}>{t('join-organization.form.skip-button')}</Button>
                        <Button variant="filled" color="dark" loading={loading} onClick={(e) => joinOrganizationHandler(e)}>{t('join-organization.form.join-button')}</Button>

                        {/* <IconButton text={t('join-organization.form.skip-button')} outlined onClick={() => navigate("/")} />
                        <IconButton text={t('join-organization.form.join-button')} primary onClick={(e) => joinOrganizationHandler(e)} /> */}
                        {/* {loading ? <Spinner /> : <span />} */}
                    </Flex>
                </div>
            }

        </Page>
    )
}

export default JoinOrganization;