import { Button } from "@mantine/core"
import { useState } from "react";
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next";


const CreateFollowUpQuestionsButton = ({ text, contentScanId, firebase, onFinished, isRecreateVariant }) => {
    const { t } = useTranslation(['common']);

    const [loading, setLoading] = useState(false);

    const functions = firebase.app().functions("europe-west1");
    const getFollowUpQuestions = functions.httpsCallable("getFollowUpQuestions");

    const createFollowUpQuestions = async () => {
        if (loading) return;

        setLoading(true)

        try {
            const result = await getFollowUpQuestions({ text: text, contentScanId: contentScanId });
            const data = result.data;
            setLoading(false);

            onFinished();
        } catch (error) {
            toast.error(t('scan.fuq.question-creation-error'), {
                duration: 8000,
                position: 'bottom-center',
            })

            setLoading(false);
        }
    }

    return (
        <Button className="plausible-event-name=createFollowUpQuestions" loading={loading} variant={isRecreateVariant ? "outline" : "filled"} onClick={() => createFollowUpQuestions()}>
            {isRecreateVariant ? t('scan.fuq.recreate-questions') : t('scan.fuq.create-questions')}
        </Button>
    )
}

export default CreateFollowUpQuestionsButton;