import React, { useEffect, useState } from 'react';

const useGetOrganizationMembers = (firebase, organizationId) => {
    const [loading, setLoading] = useState(false)
    const [members, setMembers] = useState([]);

    const queryData = async () => {
        setLoading(true)

        const functions = firebase.app().functions("europe-west1");
        const getOrganizationMembers = functions.httpsCallable("getOrganizationMembers")

        try {
            const result = await getOrganizationMembers({ organizationId: organizationId });

            const members = result.data.members;

            setLoading(false);
            setMembers(members);
        } catch (error) {
            setLoading(false);
            console.log(error.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (organizationId) queryData();
    }, [organizationId])

    return { members: members, loading: loading };
}

export default useGetOrganizationMembers;