import IconButton from '../../molecules/IconButton/iconButton';
import style from './sign-in.module.css'
import { useNavigate } from 'react-router-dom';
import SignInForm from '../../organisms/sign-in-form/signInForm';
import Page from '../../molecules/page/page';
import { useTranslation } from 'react-i18next';

const SignIn = ({ auth }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();

    return (
        <div className={style.signInWrapper}>
            <div className={style.mainWrapper}>
                <Page>
                    <SignInForm auth={auth} nextPageUrl={"/"}/>
                </Page>
            </div>

            <div className={style.signUpNavWrapper}>
                <div className={style.signUpNavContent}>

                    <h1 className={style.h1Inverted}>{t('auth.sign-in.page.title')}</h1>
                    <p className={style.h1Inverted}>{t('auth.sign-in.page.description')}</p>
                    <div className={style.signUpNavButton}>
                        <IconButton outlined text={t('auth.sign-in.page.switch-to-create-user-button')} onClick={() => navigate("/sign-up")} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SignIn;