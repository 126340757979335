import { Link, useLocation } from "react-router-dom";
import style from "./navbar-item.module.css"
import { useTranslation } from "react-i18next";

const NavbarItem = ({ name, url, icon=undefined, hideOnMobile }) => {
    const location = useLocation();
    const {t} = useTranslation(['common']);

    let isSelected = false 
    if(window.location.href.split("/").includes(url) && url !== "") isSelected = true 
    if((location.pathname === "/" || location.pathname === "home") && name === t('navbar.home')) isSelected = true 

    return (
        <Link className={hideOnMobile ? style.hide : ""} to={`/${url}`}>
            <div className={`${style.wrapper} ${isSelected ? style.selectedMobile: ""}`}>
                <p className={`${style.name} ${isSelected ? style.selected: ""}`}>{name}</p>
                {icon ? <img className={style.icon} src={icon} alt={name}></img> : null}
            </div>
        </Link>
    )
}

export default NavbarItem;
