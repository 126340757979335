import { useEffect, useRef } from "react";
import style from "./modal.module.css"

const Modal = (props) => {

    const componentRef = useRef(null);

    useEffect(() => {
        // Add event listener to detect clicks outside of the component
        const handleOutsideClick = (e) => {
            if (componentRef.current && !componentRef.current.contains(e.target)) {
                props.setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        // Cleanup the event listener when component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [componentRef]);



    if (props.isOpen) return (
        <div className={`${style.modalWrapper}`}>
            <div className={style.modalBox} ref={componentRef}>
                {props.children}
            </div>
        </div>  
    )

    return null;
}

export default Modal;
