import style from "./infoSummary.module.css"
import checkmark from "./../../assets/check.png"
import warning from "./../../assets/warning.png"
import aiwarning from "./../../assets/aiwarning.png"

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InfoSummary = ({ scan, children }) => {
    const { t } = useTranslation(['common']);

    const paragraphs = scan.paragraphs;

    let numberOfAIParagraphs = 0;
    let numberOfFuzzyParagraphs = 0;

    const upperTreshold = scan.version === "2.1" ? 0.9999 : 0.9995;

    paragraphs.forEach((p) => {
        if (p.aiScore >= upperTreshold && p.aiScore <= 1) numberOfAIParagraphs++;
        else if (p.aiScore > 0.983 && p.aiScore < upperTreshold) numberOfFuzzyParagraphs++;
    })

    const numberOfOriginalParagraphs = paragraphs.length - (numberOfAIParagraphs + numberOfFuzzyParagraphs)

    if (numberOfAIParagraphs > 0) return (
        <div className={`${style.wrapper}`}>
            <div className={style.row}>
                <img className={style.icon} src={aiwarning} alt="" />
                <div>
                    <p className={style.text}>{t('scan.summary.ai')}</p>
                </div>
            </div>
            {children}
        </div>
    )

    if (numberOfFuzzyParagraphs / paragraphs.length > 0.5) return (
        <div className={`${style.wrapper}`}>
            <div className={style.row}>
                <img className={style.icon} src={warning} alt="" />
                <div>
                    <p className={style.text}>{t('scan.summary.unsure')}</p>
                </div>
            </div>
            {children}
        </div>
    )

    if (numberOfFuzzyParagraphs > 0) return (
        <div className={`${style.wrapper}`}>
            <div className={style.row}>
                <img className={style.icon} src={checkmark} alt="" />
                <div>
                    <p className={style.text}>{t('scan.summary.probably-original')}</p>
                </div>
            </div>
            {children}
        </div>
    )

    return (
        <div className={`${style.wrapper}`}>
            <div className={style.row}>
                <img className={style.icon} src={checkmark} alt="" />
                <div>
                    <p className={style.text}>{t('scan.summary.original')}</p>
                </div>
            </div>
            {children}
        </div>
    )
}

export default InfoSummary;