import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Button, Modal, Container, Flex, Title, Stack, Text } = require("@mantine/core");
const { useDisclosure, useMediaQuery } = require("@mantine/hooks");

const LeaveOrganization = ({ firebase, auth, organization, user }) => {
    const { t } = useTranslation(['common']);

    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery("(max-width: 50em)");

    const leaveOrganizationQuery = async () => {
        setLoading(true)

        const functions = firebase.app().functions("europe-west1");
        const leaveOrganization = functions.httpsCallable("leaveOrganization")

        try {
            const result = await leaveOrganization({ organizationId: organization.organizationId, userID: user.userID });

            const members = result.data.members;

            setLoading(false);
            window.location.href = `${window.location.origin}/`;
        } catch (error) {
            setLoading(false);
            console.log(error.message)
        }
        setLoading(false)
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                transitionProps={{ transition: 'fade', duration: 200 }}
                fullScreen={isMobile}
                centered
                size="md"
                title={
                    <Title fw={600} order={5}>
                        {user.userID === auth.currentUser.uid ?
                            `${t('organization.leave.title')} ${organization.organizationData.name}?`
                            :
                            `${t('organization.remove.title')} ${user.name ?? user.email}?`
                        }
                    </Title>
                }
            >
                <Stack>
                    <Text fz={14}>
                        {user.userID === auth.currentUser.uid ?
                            t('organization.leave.description')
                            :
                            t('organization.remove.description')
                        }
                    </Text>

                    <Flex
                        columnGap={"0.5rem"}
                    >
                        <Button variant="outline" color="dark" onClick={close}>
                            {t('common.cancel')}
                        </Button>
                        <Button loading={loading} variant="outline" color="red" onClick={() => leaveOrganizationQuery()}>
                            {user.userID === auth.currentUser.uid ? t('organization.leave.button') : t('organization.remove.button')}
                        </Button>
                    </Flex>
                </Stack>
            </Modal>


            <Button variant="outline" color={user.userID === auth.currentUser.uid ? "blue" : "gray"} compact onClick={open} >{user.userID === auth.currentUser.uid ? t('organization.leave.button') : t('organization.remove.button')}</Button>
        </>
    )
}

export default LeaveOrganization;
