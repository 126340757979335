import { Avatar } from "@mantine/core"

const UserPreview = ({ user, onClick }) => {
    let isSelected = false
    if (window.location.href.split("/").includes("user")) isSelected = true

    if (!user.displayName) return (
        <Avatar
            color="initials"
            radius="xl"
            variant="filled"
            onClick={onClick}
            style={{cursor: "pointer"}}
        >
            {user.email.charAt(0).toUpperCase()}
        </Avatar>
    )

    return (
        <Avatar
            color="initials"
            radius="xl"
            variant="filled"
            onClick={onClick}
            style={{cursor: "pointer"}}
            src={user.photoURL}
        >
            {user.email.charAt(0).toUpperCase()}
        </Avatar>
    )
}

export default UserPreview;