import { Box, Button, CopyButton, Divider, Flex, Modal, Stack, Text, TextInput, Textarea, Title, Tooltip } from "@mantine/core"
import style from './follow-up-questions.module.css'
import CreateFollowUpQuestionsButton from "../../../../organisms/create-follow-up-questions/create-follow-up-questions";

import crossIcon from '../../../../assets/cross.svg'
import editIcon from '../../../../assets/edit.png'

import { useState } from "react";
import IconButton from "../../../../molecules/IconButton/iconButton";

import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useTranslation } from "react-i18next";

const FollowUpQuestions = ({ firebase, firestore, scan, contentScanId }) => {
    const { t } = useTranslation(['common']);

    const isMobile = useMediaQuery('(max-width: 700px)');

    const [opened, { open, close }] = useDisclosure(false);

    const [newQuestion, setNewQuestion] = useState("");
    const [newAnswer, setNewAnswer] = useState("");

    const getQuestionsString = () => {
        let string = `${t('scan.fuq.tab-title')}:\n\n`;

        scan.followUpQuestions.forEach((followUpQuestion, index) => {
            string += `${index + 1}. ${followUpQuestion.question}\n`
            string += `${t("scan.fuq.answer")}: ${followUpQuestion.answer}\n\n`
        })

        return string;
    }

    const removeFollowUpQuestion = async (index) => {
        const newFollowUpQuestions = [...scan.followUpQuestions];
        newFollowUpQuestions.splice(index, 1);

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            followUpQuestions: newFollowUpQuestions,
        })
    }

    const addFollowUpQuestion = async () => {
        const newFollowUpQuestions = [...scan.followUpQuestions];
        newFollowUpQuestions.unshift({
            question: newQuestion,
            answer: newAnswer,
            state: null,
            creator: "USER",
        });

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            followUpQuestions: newFollowUpQuestions,
        })

        setNewQuestion("")
        close()
    }

    const editFollowUpQuestion = async (index, newQuestionText, newAnswerText, followUpQuestion) => {
        const newFollowUpQuestions = [...scan.followUpQuestions];

        if (newFollowUpQuestions[index].question === newQuestionText) return;

        newFollowUpQuestions[index] = {
            question: newQuestionText,
            answer: newAnswerText,
            state: followUpQuestion.state,
            creator: followUpQuestion.creator && followUpQuestion.creator === "USER" ? "USER" : "USER_EDITED",
        };

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            followUpQuestions: newFollowUpQuestions,
        })
    }

    return (
        <div className={style.wrapper}>
            <div className={style.titleWrapper}>
                <h4>{t('scan.fuq.tab-title')}</h4>
                <div className={style.titleWrapperLeft}>
                    <Flex direction={isMobile ? "column" : "row"} columnGap={"sm"} rowGap={"sm"}>
                        {scan.followUpQuestions && <CreateFollowUpQuestionsButton
                            firebase={firebase}
                            text={scan.text}
                            contentScanId={contentScanId}
                            onFinished={() => false}
                            isRecreateVariant
                        />
                        }

                        {scan.followUpQuestions && <CopyButton value={getQuestionsString()}>
                            {({ copied, copy }) => (
                                <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? t('scan.fuq.copied-question') : t('scan.fuq.copy-question')}
                                </Button>
                            )}
                        </CopyButton>}
                    </Flex>
                </div>
            </div>
            {!scan.followUpQuestions && <div>
                <p>{t('scan.fuq.description')}</p>
                <br />
                <CreateFollowUpQuestionsButton firebase={firebase} text={scan.text} contentScanId={contentScanId} onFinished={() => false} />
            </div>
            }

            {scan.followUpQuestions && <div className={style.cardList}>
                <Modal opened={opened} onClose={close} title={<Title order={5} ml={"1rem"}>{t("common.edit")}</Title>} centered>
                    <Stack p={"1rem"}>
                        <TextInput
                            label={t("scan.fuq.question")}
                            value={newQuestion}
                            onChange={(e) => { setNewQuestion(e.target.value) }}
                            data-autofocus />
                        <Textarea
                            label={t("scan.fuq.answer")}
                            value={newAnswer}
                            onChange={(e) => { setNewAnswer(e.target.value) }}
                            autosize
                            minRows={2}
                            maxRows={6}
                        />

                        <Flex gap={"0.25rem"} w={"fit-content"}>
                            <IconButton text={t('common.save')} disabled={newQuestion.length === 0} primaryBlue onClick={() => { addFollowUpQuestion(); close() }} />
                            <IconButton text={t('common.cancel')} outlinedPrimary onClick={() => close()} />
                        </Flex>
                    </Stack>
                </Modal>

                <Button mb={"1rem"} variant="subtle" size="md" onClick={() => open()}>{`+ ${t('scan.fuq.add-question')}`}</Button>

                {scan.followUpQuestions.map((followUpQuestion, index) => (
                    <QuestionCard key={`${followUpQuestion.question}-${index}`} followUpQuestion={followUpQuestion} index={index} removeFollowUpQuestion={removeFollowUpQuestion} editFollowUpQuestion={editFollowUpQuestion} />
                ))}
            </div>}
        </div>
    )
}

export default FollowUpQuestions;


const QuestionCard = ({ followUpQuestion, index, removeFollowUpQuestion, editFollowUpQuestion }) => {
    const { t } = useTranslation(['common']);

    const [opened, { open, close }] = useDisclosure(false);

    const [question, setQuestion] = useState(followUpQuestion.question)
    const [answer, setAnswer] = useState(followUpQuestion.answer)

    const isMobile = useMediaQuery('(max-width: 700px)');

    return (
        <div key={`follow-up-question-${index}-${extractFirst12NonWhitespaceCharacters(followUpQuestion.question)}`}>

            <Modal opened={opened} onClose={close} title={<Title order={5} ml={"1rem"}>{t("common.edit")}</Title>} centered>
                <Stack p={"1rem"}>
                    <TextInput
                        label={t("scan.fuq.question")}
                        value={question}
                        onChange={(e) => { setQuestion(e.target.value) }}
                        data-autofocus />
                    <Textarea
                        label={t("scan.fuq.answer")}
                        value={answer}
                        onChange={(e) => { setAnswer(e.target.value) }}
                        autosize
                        minRows={2}
                        maxRows={6}
                    />

                    <Flex gap={"0.25rem"} w={"fit-content"}>
                        <IconButton text={t('common.save')} disabled={question.length === 0} primaryBlue onClick={() => { editFollowUpQuestion(index, question, answer, followUpQuestion); close() }} />
                        {!isMobile && <IconButton text={t('common.cancel')} outlinedPrimary onClick={() => { close(); setQuestion(followUpQuestion.question) }} />}
                        <div className={style.removeFollowUpQuestionOption}><IconButton className="plausible-event-name=deleteFollowUpQuestion" text={t('common.delete')} negative onClick={() => { removeFollowUpQuestion(index); close() }} /></div>
                    </Flex>
                </Stack>
            </Modal>
            {/* className={style.card} */}
            <div onClick={() => isMobile && open()}>
                <Divider />
                <Flex py={"1rem"} px={"1rem"} gap={"0.5rem"}>


                    <Text fz={"18px"} fw={500}>{`${index + 1}.`}</Text>
                    {followUpQuestion.answer ?
                        <Flex direction={"column"} gap={"0.5rem"}>
                            <Text fz={"18px"} fw={500}>{followUpQuestion.question}</Text>

                            <Text fz={15} >
                                <i>{`${t('scan.fuq.answer')}: `}</i><span>{followUpQuestion.answer}</span>
                                
                            </Text>
                        </Flex>
                        :
                        <Text >{followUpQuestion.question}</Text>
                    }

                    <div className={style.cardItemRight}>
                        <span />
                        <Tooltip label={t('common.edit')} withArrow>
                            <button className={style.optionIconButton} onClick={() => open()}>
                                <img className={style.optionIcon} src={editIcon} alt="edit"></img>
                            </button>
                        </Tooltip>

                        <Tooltip label={t('common.delete')} withArrow>
                            <button className={`${style.optionIconButton} plausible-event-name=deleteFollowUpQuestion`} onClick={() => removeFollowUpQuestion(index)}>
                                <img className={style.optionIcon} src={crossIcon} alt="delete"></img>
                            </button>
                        </Tooltip>
                    </div>
                </Flex>


            </div>
        </div>
    )
}

function extractFirst12NonWhitespaceCharacters(inputString) {
    let stringWithoutWhitespace = inputString.replace(/\s/g, '');
    return stringWithoutWhitespace.slice(0, 12);;
}