import { useState } from "react"
import IconButton from "../../molecules/IconButton/iconButton";
import { Divider, Flex } from "@mantine/core";

import style from './general-feedback.module.css'
import { useTranslation } from "react-i18next";

import editIcon from './../../assets/edit.png'

const GeneralFeedback = ({ scan, contentScanId, firestore }) => {
    const { t } = useTranslation(['common']);

    const [isEditing, setIsEditing] = useState(false)

    const [text, setText] = useState(scan.generalFeedback ?? "");
    const [loading, setLoading] = useState(false)

    const handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const saveGeneralFeedback = async () => {
        setLoading(true);

        const documentRef = firestore.collection("content-scan").doc(contentScanId);
        await documentRef.update({ generalFeedback: text });

        setLoading(false);
        setIsEditing(false)
    }

    if (isEditing) return (
        <div className={style.wrapper}>
            <Divider />
            <h4>{t('new-scan.general-feedback')}</h4>

            <textarea autoFocus className={style.textarea} value={text} onChange={(e) => { setText(e.target.value); handleKeyDown(e) }}></textarea>

            <div className={style.actionGroup}>
                <IconButton outlinedPrimary text={t('common.cancel')} onClick={() => setIsEditing(false)} />
                <IconButton primaryBlue text={t('new-scan.add-text')} onClick={() => saveGeneralFeedback()} />
            </div>

        </div>
    )

    if (scan.generalFeedback && scan.generalFeedback.length !== "") return (
        <div className={style.wrapper}>
            <Divider />

            <Flex columnGap={"1rem"} align={"center"}>
                <h4>{t('new-scan.general-feedback')}</h4>

                <button className={style.editIconButton} onClick={() => setIsEditing(true)}>
                    <img className={style.editIcon} src={editIcon} alt="Edit general feedback"></img>
                </button>
            </Flex>

            <pre className={style.text}>
                {scan.generalFeedback}
            </pre>
        </div>
    )

    return (
        <div className={style.wrapper}>
            <Divider mb={"1rem"} />

            <IconButton primaryBlue text={t('new-scan.add-general-feedback')} onClick={() => setIsEditing(true)} />
        </div>
    )
}

export default GeneralFeedback;
