import { useState } from "react"
import Page from "../../molecules/page/page"
import { Flex } from '@mantine/core';

import IconButton from "../../molecules/IconButton/iconButton";
import { useNavigate } from "react-router-dom";
import Spinner from "../../molecules/Spinner/spinner";
import { useTranslation } from "react-i18next";
import style from './dpa-form.module.css'
import { toast } from "react-hot-toast";

const DPAForm = ({ firestore, auth, firebase }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const organizationId = window.location.href.split("/")[window.location.href.split("/").length - 1]

    const [email, setEmail] = useState("");
    const [organizationLegalName, setOrganizationLegalName] = useState("");
    const [note, setNote] = useState("");


    const submitDPAForm = async () => {
        setLoading(true)

        const foldersRef = firestore.collection("DPA-requests")
        await foldersRef.add({
            email: email,
            organizationLegalName: organizationLegalName,
            note: note,
            requesterUid: auth.currentUser.uid,
            timestampCreated: new Date(),
            organizationId: organizationId,
        })

        setLoading(false)

        toast.success(t('dpa.toast-success'), {
            duration: 4000,
            position: 'bottom-center',
        })
        navigate(-1)
    }

    const validateForm = () => {
        if (email.length < 2) return false;
        if (organizationLegalName.length < 2) return false;
        return true;
    }

    return (
        <Page>
            <div style={{ maxWidth: "30rem", margin: "auto" }}>
                <div style={{ width: "fit-content" }}>
                    <IconButton text={`← ${t('common.back')}`} onClick={() => navigate("/organization")} />
                </div>

                <h4>{t('dpa.title')}</h4>
                
                <p>{t('dpa.description')}</p>
                <br />
                <label>{t('dpa.email-label')}</label>
                <input className="input" value={email} autoFocus onChange={(e) => setEmail(e.target.value)}></input>
                <br />
                <br />
                <label>{t('dpa.legal-name-label')}</label>
                <input className="input" value={organizationLegalName} onChange={(e) => setOrganizationLegalName(e.target.value)}></input>
                <br />
                <br />
                <label>{t('dpa.note-label')}</label>
                <textarea className={style.textarea} value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                <br />
                <br />
                <IconButton primaryBlue text={t('dpa.submit')} onClick={() => submitDPAForm()} disabled={!validateForm()} />
            </div>
        </Page>
    )
}

export default DPAForm;