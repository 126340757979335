import { Avatar, Badge, Table, Group, Text, Select, ScrollArea, Button, Skeleton, Container, Flex } from '@mantine/core';
import React from "react";
import LeaveOrganization from '../../molecules/leaveOrganization/leaveOrganization';
import SelectOrganizationRole from '../selectOrganizationRole/selectOrganizationRole';
import { useTranslation } from 'react-i18next';

export default function OrganizationMemberTable({ firebase, auth, data, loading, organization }) {
  const { t } = useTranslation(['common']);

  const rows = data.map((item) => (
    <tr key={item.id}>
      {item.name ?
        <td>
          <Flex columnGap={"sm"} align={"center"}>
            <Avatar
              size={40}
              src={item.photoURL}
              radius={40}
              variant={item.userID === auth.currentUser.uid ? "filled" : "light"}
            >
              {item.email.charAt(0).toUpperCase()}
            </Avatar>
            <div>
              <Text fz="sm" fw={500}>
                {item.name}
              </Text>
              <Text fz="xs" c="dimmed">
                {item.email}
              </Text>
            </div>
          </Flex>
        </td>
        :
        <td>
          <Flex columnGap={"sm"} align={"center"}>
            <Avatar
              size={40}
              radius={40}
              color="blue"
              variant={item.userID === auth.currentUser.uid ? "filled" : "light"}
            >
              {item.email.charAt(0).toUpperCase()}
            </Avatar>

            <div>
              <Text fz="sm" fw={500}>
                {item.email}
              </Text>
              <Text fz="xs" c="dimmed">
                {""}
              </Text>
            </div>
          </Flex>
        </td>
      }

      <td>
        <SelectOrganizationRole firebase={firebase} auth={auth} organization={organization} user={item} />
      </td>
      <td>
        <Flex w={"100%"} justify={"right"}>
          {organization && (item.userID === auth.currentUser.uid || organization.organizationData.admins.includes(auth.currentUser.uid)) ?
            <LeaveOrganization firebase={firebase} auth={auth} organization={organization} user={item} />
            : null
          }
        </Flex>
      </td>
    </tr>
  ));

  const loadingRows = organization?.organizationData?.members.map((item, index) => (
    <tr key={`loading-row-${index}`}>
      <td>
        <Flex columnGap={"sm"} align={"center"}>
          <Skeleton height={40} radius="xl" width={40} />
          <div>
            <Container py={"0.8rem"}>
              <Skeleton height={16} radius="xl" width={"15rem"} />
            </Container>
          </div>
        </Flex>
      </td>
      <td>
        <Container py={"0.8rem"} px={"1rem"}>
          <Skeleton height={16} radius="xl" width={"4rem"} />
        </Container>
      </td>
      <td>
        <Container py={"0.8rem"} px={"1rem"}>
          <Skeleton height={16} radius="xl" width={"8rem"} />
        </Container>
      </td>
    </tr>

  )) ?? [];

  return (
    <ScrollArea>
      <Table miw={600} verticalSpacing="sm">
        <thead>
          <tr>
            <th>{t('organization.members-table.labels.members')}</th>
            <th>{t('organization.members-table.labels.role')}</th>
            <th>
              <Flex w={"100%"} justify={"right"}>
                {`${organization.organizationData.members.length}/${organization.organizationData.numberOfUserLicences} ${t("organization.user-licences-label")}`}
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>{loading ? loadingRows : rows}</tbody>
        <div style={{ height: "10rem" }}></div>
      </Table>
    </ScrollArea>
  );
}
