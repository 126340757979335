import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Flex } from '@mantine/core';
import IconButton from '../../molecules/IconButton/iconButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import style from "./reset-password.module.css"

const ResetPassword = ({ auth }) => {
    const { t } = useTranslation(['common']);

    const [opened, { open, close }] = useDisclosure(false);

    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)


    const validateEmail = (email) => {
        if (email === null) return false;

        // Split the email into two parts: the username and domain
        const emailParts = email.split('@');
        if (emailParts.length !== 2) {
            return false; // email should have only one @ symbol
        }

        const username = emailParts[0];
        const domain = emailParts[1];

        // Check if the domain has a valid format
        const domainParts = domain.split('.');
        if (domainParts.length < 2) {
            return false; // domain should have at least one dot
        }

        for (let i = 0; i < domainParts.length; i++) {
            if (domainParts[i].length < 2) {
                return false; // each domain part should have at least two characters
            }
        }

        // Check if the username has a valid format
        if (username.length < 1) {
            return false; // username should not be empty
        }

        return true;
    };


    const handlePasswordReset = async (email) => {
        setLoading(true)

        try {
            await auth.sendPasswordResetEmail(email);
            // Display a success message to the user

            setCompleted(true)
        } catch (error) {
            // Handle errors (e.g., email not found)
            console.error('Error sending password reset email:', error.message);
        }

        setLoading(false)
    };

    const handleClose = () => {
        setEmail("")
        setLoading(false)
        setCompleted(false)

        close();
    }


    return (
        <>
            <span className={style.link} onClick={open}>{t("auth.reset-password.title")}</span>

            <Modal onClose={(handleClose)} opened={opened} padding={"2rem"} keepMounted fullScreen>

                <div style={{ maxWidth: "300px", margin: "auto", marginTop: "5rem" }}>
                    {completed ?
                        <>
                            <h3>{t("auth.reset-password.completed.title")}</h3>
                            <p>{t("auth.reset-password.completed.description")}</p>
                            <br />
                            <IconButton text={t("common.close")} outlinedPrimary onClick={handleClose} />
                        </>
                        :
                        <>
                            <h3>{t("auth.reset-password.title")}</h3>
                            <p>{t("auth.reset-password.description")}</p>
                            <br />
                            <Flex direction={"column"} rowGap={"1rem"}>
                                <Flex>
                                    <input autoFocus value={email} onChange={(e) => setEmail(e.target.value)} className="input" placeholder={t('auth.sign-in.form.email-label')}></input>
                                </Flex>
                                <Button onClick={() => handlePasswordReset(email)} disabled={!validateEmail(email)} loading={loading}>{t("auth.reset-password.reset-button")}</Button>
                                <span style={{ height: "3rem" }} />
                                <IconButton text={t("common.cancel")} outlinedPrimary onClick={handleClose} />
                            </Flex>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}

export default ResetPassword;