import { useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from './data-administration.module.css'
import { useTranslation } from "react-i18next";

const DataAdministration = ({ auth, firestore }) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['common']);

    return (
        <Page>
            {<div style={{ width: "fit-content" }}>
                <IconButton text={`← ${t('common.back')}`} onClick={() => navigate(-1)} />
            </div>}
            <div className={style.wrapper}>
                <h3>{t('user.administrate-data.title')}</h3>
                <p>{`${t('user.administrate-data.delete-scans-description')}:`}</p>
                <IconButton text={t('user.administrate-data.delete-scans-button')} outlined onClick={() => navigate("/delete-data")} />
                <br />
                <br/>
                <p>{`${t('user.administrate-data.delete-user-description')}:`}</p>
                
                <IconButton text={t('user.delete-user-button')} negative onClick={() => navigate("/delete-user")} />
            </div>
        </Page>
    )
}

export default DataAdministration;