import { useState } from "react"
import IconButton from "../../molecules/IconButton/iconButton"
import Page from "../../molecules/page/page"
import Spinner from "../../molecules/Spinner/spinner"
import style from './new-test-scan.module.css'

const NewTestScan = ({ firestore, auth, firebase }) => {
    const folderIdUnchecked = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const folderId = folderIdUnchecked === "new-scan" ? null : folderIdUnchecked;

    const [name, setName] = useState("")
    const [isAIGenerated, setIsAIGenerated] = useState(false)
    const [text, setText] = useState("")
    const [loading, setLoading] = useState(false);


    const functions = firebase.app().functions("europe-west1");
    const testScan = functions.httpsCallable("testScan")

    const sendMessage = async (e) => {
        e.preventDefault();
        if(loading) return;

        setLoading(true)

        console.log(isAIGenerated)

        try {
            await testScan({ text: text, name: name, isAIGenerated: isAIGenerated });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const countWords = (text) => {
        return text.split(" ").length;
    }


    return (
        <Page>
            <h1>Test-ai-scan-endpoint</h1>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className={style.topButtons}>
                    <div>
                        <label>Name</label><br />
                        <input type="text" className={style.input} placeholder="Navn på undersøkelsen..." value={name} onChange={(e) => setName(e.target.value)}></input>
                    </div>
                    <div>
                        <label>AI*</label><br />
                        <input type="checkbox" value={isAIGenerated} onChange={(e) => setIsAIGenerated(e.target.checked)}></input>
                    </div>
                </div>
                <div className={style.topBar}>
                    <label>{`${countWords(text) - 1}/5000 ord`}</label>
                    <label>{countWords(text) - 1 < 200 ? `200 ord minimum` : ""}</label>
                </div>
                <textarea className={style.textarea} placeholder="Tekst..." value={text} onChange={(e) => { setText(e.target.value); handleKeyDown(e) }}></textarea>
                <div className={style.actionGroup}>
                    <IconButton primary text={"Sjekk tekst →"} onClick={(e) => sendMessage(e)} disabled={countWords(text) < 201 || countWords(text) > 5001} />
                    {loading ? <Spinner /> : <span />}
                    <label className={style.topBarRight}>Bokmål | Nynorsk | Engelsk</label>
                </div>

            </form>
        </Page>
    )
}

export default NewTestScan;
