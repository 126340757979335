import React, { useEffect, useState } from 'react';

const useGetOrganizationInvitation = (firestore, organizationInvitationId) => {
  const [organizationInvitation, setOrganizationInvitation] = useState({
    id: "",
    data: null,
  });

  const queryData = async () => {

    try {
        const organizationQuery = firestore.collection('organization-invitations').doc(organizationInvitationId);
        const organizationInvitationDoc = await organizationQuery.get();

        if (organizationInvitationDoc.exists) {
            setOrganizationInvitation({
            id: organizationInvitationDoc.id,
            data: organizationInvitationDoc.data(),
          })
        }
      }
      catch (error) {
        console.log(error.message)
      }
  }

  useEffect(() => {
    queryData();
  }, [])

  return { organizationInvitation: organizationInvitation };
}

export default useGetOrganizationInvitation;