import { Flex, Text } from '@mantine/core';
import style from './technical-terms.module.css';

import checkmark from './../../../../assets/checkmark.svg'
import cross from './../../../../assets/crossRed.svg'
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TechnicalTermsTab = ({ scan }) => {
    const { t } = useTranslation(['common']);

    const preRef = useRef(null);
    const [elements, setElements] = useState([]);
    const [occurences, setOccurences] = useState([])

    const technicalTerms = [];
    const newTechnicalTermsOccurence = []
    scan.technicalTerms.forEach((technicalTerm) => {
        technicalTerms.push(technicalTerm.technicalTerm.toLowerCase())
        newTechnicalTermsOccurence.push({ technicalTerm: technicalTerm.technicalTerm.toLowerCase(), numberOfOccurences: 0 })
    })

    const technicalTermsOccurence = newTechnicalTermsOccurence;

    useEffect(() => {
        const originalText = scan.text;
        let terms = [];

        // Add technical terms as comments for highlighting
        technicalTerms.forEach(technicalTerm => {
            let index = originalText.toLowerCase().indexOf(technicalTerm);
            while (index !== -1) {
                terms.push({
                    term: technicalTerm,
                    isTechnicalTerm: true,
                    startIndex: index,
                    endIndex: index + technicalTerm.length
                });
                index = originalText.indexOf(technicalTerm, index + 1);
            }
        });

        // Sort comments by their 'startIndex'
        terms = terms.sort((a, b) => a.startIndex - b.startIndex);

        let currentOffset = 0;
        const elements = terms.reduce((acc, term, index) => {

            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, term.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment or technical term
            acc.push(
                <mark
                    key={term.startIndex}
                    data-comment={term.term}
                    data-index={index}
                    className={style.mark}
                >
                    {originalText.slice(term.startIndex, term.endIndex)}
                </mark>
            );

            //Count number of occurences
            const foundItemIndex = technicalTermsOccurence.findIndex((item) => item.technicalTerm.toLowerCase() === term.term.toLowerCase());

            if (technicalTermsOccurence[foundItemIndex]) {

                technicalTermsOccurence[foundItemIndex] = {
                    ...technicalTermsOccurence[foundItemIndex],
                    numberOfOccurences: technicalTermsOccurence[foundItemIndex].numberOfOccurences += 1,
                };
            }

            // Update offset to the end of the current comment
            currentOffset = term.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
        setOccurences(technicalTermsOccurence)
    }, []);


    return (
        <div className={style.wrapper}>
            <div
                id="text-container"
                className={style.text}
                ref={preRef}
                style={{ whiteSpace: 'pre-line' }}
            >
                {elements}
            </div>
            <div>
                <div>
                    <Text fw={"bold"}>{`${occurences.filter((occurence) => occurence.numberOfOccurences > 0).length}/${occurences.length} ${t('scan.terms.tab-title')}:`}</Text>
                    {occurences.map((occurence, index) => (
                        <Flex columnGap={"0.25rem"} align={"center"} key={`${occurence.technicalTerm}-${index}`}>
                            <img className={style.icon} src={occurence.numberOfOccurences > 0 ? checkmark : cross} alt={occurence.numberOfOccurences > 0 ? "Yes" : "No"}></img>
                            <Text>{capitalizeFirstLetter(occurence.technicalTerm)}</Text>
                        </Flex>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default TechnicalTermsTab;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
