import { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import IconButton from "../../molecules/IconButton/iconButton"
import Page from "../../molecules/page/page"
import Spinner from "../../molecules/Spinner/spinner"
import style from './ai-scan.module.css'
import Modal from "../../molecules/modal/modal"
import CreateFolder from "../../molecules/createFolder/createFolder"
import Folder from "../../molecules/folder/folder"

import checkmark from '../../assets/checkmark.svg'
import arrowRight from '../../assets/right-arrow.png'
import arrowLeft from '../../assets/left-arrow.png'

import { useTranslation } from "react-i18next"

import { useGetNumberOfScans } from "../../hooks/useGetNumberOfScans"
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext"
import { Box, Button, Flex } from "@mantine/core"
import AIResult from "../scan/tabs/ai-result"
import { useMediaQuery } from "@mantine/hooks"
import toast from "react-hot-toast"
import useGetSubscription from "../../hooks/useGetSubscription"

const AiScan = ({ firestore, auth, firebase }) => {
    const { t } = useTranslation(['common']);

    const [scan, setScan] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false)
    const isDesktopOrTablet = useMediaQuery('(min-width: 450px)');

    const functions = firebase.app().functions("europe-west1");
    const saveAiScan = functions.httpsCallable("saveAiScan");

    const saveScan = async () => {
        if (loading || !scan) return;

        setLoading(true)

        try {
            const result = await saveAiScan({ scan: scan });
            const data = result.data;
            setLoading(false);

            if (data.error) {
                toast.success(t('ai-scan.save.toast.error'), {
                    duration: 4000,
                    position: 'bottom-center',
                })
            }

            if (!data.error) {
                console.log(data)
                setIsSaved(true)

                toast.success(t('ai-scan.save.toast.success'), {
                    duration: 2000,
                    position: 'bottom-center',
                })
            };
        } catch (error) {
            console.log(error.code)

            setLoading(false);
        }
    }

    if (scan) return (
        <Page>
            <Flex justify={"space-between"} align={"center"} direction={isDesktopOrTablet ? "row" : "column"}>
                <h1>{t('ai-scan.title')}</h1>
                <Flex columnGap={"0.5rem"} justify={"right"}>
                    <Button onClick={() => setScan(undefined)}>{t('ai-scan.retry')}</Button>
                    <Button
                        leftIcon={isSaved ? <img className={style.checkmark} src={checkmark} alt="" /> : null}
                        variant="outline"
                        className={"plausible-event-name=save-scan"}
                        onClick={() => !isSaved && saveScan()}
                        loading={loading}>
                        {isSaved ? t('ai-scan.save.confirmation') : t('ai-scan.save.action')}
                    </Button>
                </Flex>
            </Flex>

            <br />
            <AIResult firebase={firebase} isDesktopOrTablet={isDesktopOrTablet} scan={scan.data} setTab={null} />
        </Page>
    )

    return (
        <AiScanForm firestore={firestore} auth={auth} firebase={firebase} setScan={setScan} />
    )

}

const AiScanForm = ({ firestore, auth, firebase, setScan }) => {

    const { t } = useTranslation(['common']);

    const { getNumberOfScans } = useGetNumberOfScans();
    const { user, organization } = useContext(UserAndOrganzationContext);

    const [numberOfScans, setNumberOfScans] = useState(0);
    const { scanLimit, wordLimit, isTrial } = useGetSubscription();

    const scansLeft = scanLimit - numberOfScans < 0 ? 0 : scanLimit - numberOfScans;

    useEffect(() => {
        getNumberOfScans(auth.currentUser.uid, user?.userData, isTrial).then((res) => setNumberOfScans(res));
    }, [])

    const folderIdUnchecked = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const folderId = folderIdUnchecked === "new-scan" ? null : folderIdUnchecked;

    const navigate = useNavigate();
    const location = useLocation();

    const functions = firebase.app().functions("europe-west1");
    const aiScan = functions.httpsCallable("aiScan");

    //States
    const [openFolderSelection, setOpenFolerSelection] = useState(false)
    const [displayCreateFolder, setDisplayCreateFolder] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null)
    const [folders, setFolders] = useState([])

    const [loading, setLoading] = useState(false);
    const [hasLoadingFiles, setHasLoadingFiles] = useState(false)


    //Text specific states
    const [file, setFile] = useState([]);

    const [showForm, setShowForm] = useState(true);

    const [name, setName] = useState("")
    const [text, setText] = useState("")
    const [textArray, setTextArray] = useState([]);


    //Functions
    const isDateMoreThan7DaysAgo = (inputDate) => {
        if (!inputDate) return false;

        const currentDate = new Date(); // Get the current date

        // Calculate the date 7 days ago from the current date
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);

        const inputDateObject = new Date(inputDate); // Convert the input date string to a Date object

        return inputDateObject < sevenDaysAgo; // Compare the input date with the date 7 days ago
    }

    const scanOneText = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true)

        try {
            const result = await aiScan({ text: text, name: name, parentId: folderId, provideFactcheck: false, provideLingualFeedback: false, technicalTerms: null, feedbackLanguage: null });
            const data = result.data;
            setLoading(false);

            if (data.error === "Rate_Limit_Exceeded") console.log("Rate_Limit_Exceeded")
            if (!data.error) {
                console.log(data)
                setScan(data)
            };
        } catch (error) {
            console.log(error.code)

            setLoading(false);
        }
    }

    const handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const countWords = (text) => {
        return text.split(" ").length;
    }

    useEffect(() => {

        const getFolders = async () => {
            const folders = []
            const foldersRef = firestore.collection("folders")
            currentFolder ?
                await foldersRef.where("uid", "==", auth.currentUser.uid).where("parentId", "==", folderId).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
                :
                await foldersRef.where("uid", "==", auth.currentUser.uid).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
            setFolders(folders)
        }

        const getCurrentFolder = async () => {
            try {
                const currentFolderRef = firestore.collection("folders").doc(folderId)
                const documentSnapshot = await currentFolderRef.get();
                if (documentSnapshot) {
                    setCurrentFolder({ id: documentSnapshot.id, ...documentSnapshot.data() })
                }
                else console.error("Error: Document not found")

            } catch (error) {
                console.error('Error fetching document data:', error);
            }
        }

        folderId && (currentFolder === null || (currentFolder && folderId !== currentFolder.id)) && getCurrentFolder(); //Avoids infinite fetches
        openFolderSelection && getFolders();

    }, [location, currentFolder, openFolderSelection, displayCreateFolder]);

    const RemainingScansWarningBox = () => (
        <div>
            {(scansLeft) <= 0 && isTrial ?
                <div className={style.warningWrapper}>
                    {organization.organizationData ?
                        organization?.organizationData?.admins && organization?.organizationData?.admins.includes(auth.currentUser.uid) ?
                            <Flex columnGap={"0.25rem"} align={"center"}>
                                <p>{t('new-scan.contact-organization-admin')}
                                    {" "}
                                    <Link to="/organization" style={{ textDecoration: "underline" }}>{t('new-scan.contact-organization-link')}</Link>
                                    {" "}
                                    {organization.organizationData.subscription === "waiting" ?
                                        t("new-scan.contact-organization-suffix-admin-trial")
                                        :
                                        t("new-scan.contact-organization-suffix-admin")
                                    }
                                </p>
                            </Flex>
                            :
                            <Flex columnGap={"0.25rem"} align={"center"}>
                                <p>
                                    {t('new-scan.contact-organization')}
                                    {" "}
                                    <Link to="/organization" style={{ textDecoration: "underline" }}>{t('new-scan.contact-organization-link')}</Link>
                                    {" "}
                                    {t("new-scan.contact-organization-suffix")}
                                </p>
                            </Flex>
                        :
                        <Flex columnGap={"0.25rem"} align={"center"}>
                            <p>{`${scansLeft}/${scanLimit} ${t('new-scan.remaining-trial')}`}</p>
                            <Link to="/upgrade" style={{ textDecoration: "underline" }}>{t('new-scan.buy')}</Link>
                        </Flex>
                    }
                </div>
                :
                <p>{`${scansLeft}/${scanLimit} ${isTrial ? t('new-scan.remaining-trial') : t('new-scan.remaining-monthly')}`}</p>
            }
        </div>
    );

    return (
        <>
            <Modal
                isOpen={openFolderSelection}
                setIsOpen={setOpenFolerSelection}
            >
                {openFolderSelection && <div>
                    <h3>{t('folders.title')}</h3>

                    <div className={style.folderSelectionActionWrapper}>
                        <p>{folderId && currentFolder?.path ? `${t('folders.current-folder-label')}: ${currentFolder.path}` : `${t('folders.current-folder-label')}: ${t('folders.main-folder')}`}</p>
                        <div style={{ width: "fit-content" }}><IconButton primary text={t('folders.select-button')} onClick={() => setOpenFolerSelection(false)} /></div>
                    </div>
                    <br />
                    <div className={style.listHeader}>
                        <div>
                            <button className={folderId ? style.listHeaderIconButton : style.listHeaderIconButtonDisabled} onClick={() => currentFolder?.parentId ? navigate(`/new-scan/${currentFolder.parentId}`) : navigate(`/new-scan`)}><img src={arrowLeft} alt="Button back" className={style.arrowIcon}></img></button>
                            <button className={style.listHeaderIconButtonDisabled} ><img src={arrowRight} alt="Disabled button forward" className={style.arrowIcon}></img></button>
                        </div>
                        <button className={style.listHeaderButton} onClick={() => displayCreateFolder ? setDisplayCreateFolder(false) : setDisplayCreateFolder(true)}>{`+ ${t('folders.create-new-folder-button')}`}</button>
                    </div>

                    <CreateFolder auth={auth} firestore={firestore} folderId={folderId} currentFolder={currentFolder} display={displayCreateFolder} setDisplay={setDisplayCreateFolder} />

                    {folders && folders.length > 0 && folders.map((folder) => {
                        if ((!folderId && folder.parentId) || (folderId && folder.parentId !== folderId)) return null;
                        return (
                            <Folder folder={folder} pageName="new-scan" />
                        )
                    })}
                </div>}

            </Modal>
            <Page>
                <h1>{t('ai-scan.title')}</h1>
                <p><span>{t('ai-scan.description1')}</span> <Link to="/new-scan" style={{ textDecoration: "underline" }}>{t('ai-scan.click-here')}</Link> <span>{t('ai-scan.description2')}</span></p>

                {(isTrial || scansLeft <= 5) && <Box mt={"1rem"}>
                    <RemainingScansWarningBox />
                </Box>}
                {/* <p>Tekstene du legger inn her blir ikke lagret. <Link to="/new-scan" style={{ textDecoration: "underline" }}>Klikk her</Link> for mer avanserte vurderingsverktøy og filopplasting</p> */}

                <br />

                {/* <div className={style.buttonGrid} >

                    <div className={style.addTextGrid}>
                            <div className={style.fileListLabelsRight}>
                                <label className={style.fileReselector} htmlFor="reFileSelector">
                                    <span>{t('new-scan.add-files')}</span>
                                </label>
                                {!hasLoadingFiles && <input
                                    style={{ display: "none" }}
                                    id="reFileSelector"
                                    type="file"
                                    accept=".pdf,.docx"
                                    multiple
                                    onChange={(e) => { handleFileUpload(e, files, setFiles, (scansLeft) < 35 ? (scansLeft) : 35); setShowForm(false) }}
                                />}
                            </div>
                    </div>
                </div> */}

                <>
                    {/* <div className={style.divider} /> */}

                    {/* {(files.length + textArray.length) > 0 ? <h4 className={style.addNewTextTitle}>{t('new-scan.add-text')}</h4> : <div className={style.addNewTextTitle} />} */}
                    {/* <div className={style.nameWrapper}>
                        <input type="text" className="input" placeholder={`${t('new-scan.title-placeholder')}...`} autoFocus value={name} onChange={(e) => setName(e.target.value)}></input>
                    </div> */}

                    <div className={style.topBar}>
                        <label>{`${countWords(text) - 1}/${wordLimit} ${t('new-scan.words')}`}</label>
                        <label>{countWords(text) - 1 < 150 ? `150 ${t('new-scan.minimum-words')}` : ""}</label>
                    </div>
                    <textarea className={style.textarea} autoFocus placeholder={t('new-scan.text-placeholder')} value={text} onChange={(e) => { setText(e.target.value); handleKeyDown(e) }}></textarea>

                    <Flex justify={"space-between"}>
                        <Flex gap={"1rem"}>
                            {!loading ?
                                <IconButton
                                    className={"plausible-event-name=QuickScan"}
                                    primary
                                    text={`${t('new-scan.submit')} ${t('new-scan.text')} →`}
                                    onClick={(e) => scanOneText(e)}
                                    disabled={((countWords(text) < 151 || countWords(text) > (wordLimit + 1))) || hasLoadingFiles || numberOfScans >= scanLimit || scansLeft <= 0}
                                />
                                :
                                <p style={{ fontWeight: "500" }}>{`${t('new-scan.loading')} ${t('new-scan.text')}`} </p>
                            }
                            {loading ? <Spinner /> : null}
                        </Flex>
                        <label className={style.topBarRight}>{t('new-scan.languages')}</label>
                    </Flex>

                    {/* <div className={style.actionGroup} ref={textTitleRef}>
                            <IconButton outlinedPrimary text={t('common.cancel')} onClick={(e) => toggleViewForm()} />
                            <IconButton primaryBlue text={(files.length + textArray.length) > 0 ? `+ ${t('new-scan.add-text')}` : `+ ${t('new-scan.add-more-texts')}`} onClick={(e) => addText()} disabled={countWords(text) < 201 || countWords(text) > (wordLimit + 1) || numberOfScans + textArray.length >= scanLimit} />
                            <label className={style.topBarRight}>{t('new-scan.languages')}</label>
                        </div> */}
                </>
                <br />
            </Page>

            {/* <div className={style.submitGrid}>
                <div className={style.submitContent}>
                    <Flex gap={"1rem"}>
                        {!loading ?
                            (scansLeft) <= 0 ?
                                <p style={{ fontWeight: "500" }}>{`${isTrial ? t("new-scan.trial-ended-bottom") : t("new-scan.limit-reached-bottom")} `}<Link to="/upgrade" style={{ textDecoration: "underline" }}>{t("new-scan.upgrade")}</Link></p>
                                :
                                <IconButton
                                    className={"plausible-event-name=Scan"}
                                    primary
                                    text={`${t('new-scan.submit')} ${t('new-scan.text')} →`}
                                    onClick={(e) => scanOneText(e)}
                                    disabled={((countWords(text) < 151 || countWords(text) > (wordLimit + 1))) || hasLoadingFiles || numberOfScans >= scanLimit}
                                />
                            :
                            <p style={{ fontWeight: "500" }}>{`${t('new-scan.loading')} ${t('new-scan.text')}`} </p>
                        }
                        {loading ? <Spinner /> : null}
                    </Flex>
                </div>
            </div> */}
        </>
    )
}

export default AiScan;

const extractLastWords = (text, maxChars) => {
    const length = text.split("").length;
    if (length < maxChars) return text;

    let extractedText = text.substring(length - maxChars, length);
    extractedText = "..." + extractedText;
    return extractedText;
};

const extractFirstWords = (text, maxChars) => {
    var extractedText = text.substring(0, maxChars);
    var lastSpace = extractedText.lastIndexOf(" ");
    extractedText = extractedText.substring(0, lastSpace) + "...";
    return extractedText;
};
