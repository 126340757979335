import { Button } from "@mantine/core";
import toast from "react-hot-toast";
import { functions } from "../../../App";
import { priceIdFlex, priceIdOrganizationProfessional, priceIdProfessional } from "../../../contexts/priceIdVariables";
import useGetSubscription from "../../../hooks/useGetSubscription";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PricingLoader from "../../../molecules/pricing-loader";

const SwitchSubscription = ({ priceId }) => {
    const { t } = useTranslation(['common']);
    const { subscriptionType, subscriptionFromOrganization } = useGetSubscription();

    const [loading, setLoading] = useState(false);

    const isDowngrade = () => {
        if (subscriptionType === "subscription2" || subscriptionType === "subscription2-trial7") {
            return false;
        }
        if (subscriptionType === "subscription0" || subscriptionType === "subscription0-trial7") {
            return false
        }
        if (subscriptionType === "subscription1" || subscriptionType === "subscription1-trial7") {
            if (priceId === priceIdOrganizationProfessional) {
                return false;
            } else {
                return true;
            }
        }
    }

    const downgrade = isDowngrade();

    const switchSubscriptionHandler = async () => {
        setLoading(true);

        const switchSubscriptionQuery = functions.httpsCallable('switchSubscription');

        try {
            const result = await switchSubscriptionQuery({ priceId });

            toast.success(t("upgrade.subscription-updated"), {
                duration: 4000,
                position: 'bottom-center',
            })
        } catch (error) {

            toast.error(t("upgrade.subscription-updated-error"), {
                duration: 4000,
                position: 'bottom-center',
            })
        }

        setLoading(false);
    }

    if (downgrade) return (
        <>
            <PricingLoader opened={loading} text={t("upgrade.loading-label-switch")} />

            <Button
                color="dark"
                variant="outline"
                miw={"100%"}
                mt={"1rem"}
                onClick={() => switchSubscriptionHandler()}
                loading={loading}
            >
                {t("upgrade.downgrade")}
            </Button>
        </>

    )

    return (
        <>
            <PricingLoader opened={loading} text={t("upgrade.loading-label-switch")} />

            <Button
                color="dark"
                variant="outline"
                miw={"100%"}
                mt={"1rem"}
                onClick={() => switchSubscriptionHandler()}
                loading={loading}
            >
                {t("upgrade.upgrade")}
            </Button>
        </>
    )
}

export default SwitchSubscription;
