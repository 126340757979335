import { Link, useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from './user.module.css'
import { useContext, useEffect, useState } from "react";

import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";
import { useTranslation } from "react-i18next";
import { useGetNumberOfScans } from "../../hooks/useGetNumberOfScans";
import useGetSubscription from "../../hooks/useGetSubscription";
import { Avatar } from "@mantine/core";

const User = ({ firestore, auth }) => {
    const { user, organization } = useContext(UserAndOrganzationContext);
    const { subscriptionType, subscriptionFromOrganization } = useGetSubscription();

    const landingPageURLHostname = window.location.hostname.substring(4);

    const privacyPolicyLink = landingPageURLHostname === "ekte.ai" ? "https://ekte.ai/new-page" : `https://${landingPageURLHostname}/privacy-policy`;
    const termsOfServiceLink = landingPageURLHostname === "ekte.ai" ? "https://ekte.ai/vilkar-for-bruk" : `https://${landingPageURLHostname}/terms-of-service`;

    const { scanLimit, isTrial } = useGetSubscription();

    const navigate = useNavigate();
    const { t } = useTranslation(['common']);

    const [numberOfScansLastMonth, setNumberOfScansLastMonth] = useState(null)
    const scansLeft = scanLimit - numberOfScansLastMonth < 0 ? 0 : scanLimit - numberOfScansLastMonth;

    const { getNumberOfScans } = useGetNumberOfScans();

    useEffect(() => {
        getNumberOfScans(auth.currentUser.uid, user?.userData, isTrial).then((res) => setNumberOfScansLastMonth(res));
    }, [])

    useEffect(() => { }, [subscriptionType, subscriptionFromOrganization])

    return (
        <Page>
            <div className={style.wrapper}>
                {auth.currentUser.displayName ?
                    <div className={style.topGrid}>
                        <Avatar
                            color="initials"
                            radius="xl"
                            variant="filled"
                            size={"lg"}
                            src={auth.currentUser.photoURL}
                        >
                            {auth.currentUser.email.charAt(0).toUpperCase()}
                        </Avatar>

                        <h3>{auth.currentUser.displayName}</h3>
                        {organization?.organizationData?.name && <p className={style.boldText}>{organization.organizationData.name}</p>}
                        <p>{`${scansLeft}/${scanLimit} ${isTrial ? t("user.remaining-trial") : t("user.remaining-monthly")}`}</p>
                    </div>
                    :
                    <div className={style.topGrid}>
                        <Avatar
                            color="initials"
                            radius="xl"
                            variant="filled"
                            size={"lg"}
                        >
                            {auth.currentUser.email.charAt(0).toUpperCase()}
                        </Avatar>

                        <h3>{auth.currentUser.email}</h3>
                        {organization?.organizationData?.name && <p className={style.boldText}>{organization.organizationData.name}</p>}
                        <p>{`${scansLeft}/${scanLimit} ${isTrial ? t("user.remaining-trial") : t("user.remaining-monthly")}`}</p>
                    </div>
                }
                <div>
                    <br />
                    <IconButton text={t('user.sign-out')} outlined onClick={() => auth.signOut()} />
                </div>
                <div className={style.linkItemWrapper}>
                    {subscriptionType && !subscriptionFromOrganization && (subscriptionType === "trial" || subscriptionType === "waiting") ?
                        <Link to="/upgrade">
                            <p className={style.linkItem}>{t('user.buy')}</p>
                        </Link>
                        : organization.organizationData ?
                            <Link to="/organization">
                                <p className={style.linkItem}>{t('navbar.organization')}</p>
                            </Link>
                            :
                            <Link to="/upgrade">
                                <p className={style.linkItem}>{t('user.administrate-subscription')}</p>
                            </Link>
                    }
                    <a href={privacyPolicyLink} target="_blank">
                        <p className={style.linkItem}>{`${t('user.privacy-policy')} ›`}</p>
                    </a>
                    <a href={termsOfServiceLink} target="_blank">
                        <p className={style.linkItem}>{`${t('user.terms-and-conditions')} ›`}</p>
                    </a>
                    <Link to="/data-administration">
                        <p className={style.linkItem}>{t('user.administrate-data-button')}</p>
                    </Link>
                    {/* <Link to="/connect-user">
                        <p className={style.linkItem}>{t('user.connect-user.link-title')}</p>
                    </Link> */}
                </div>
                <div className={style.deleteWrapper}>
                    <IconButton text={t('user.delete-user-button')} negative onClick={() => navigate("/delete-user")} />
                </div>
            </div>
        </Page>
    )
}

export default User;