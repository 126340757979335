import { Link } from 'react-router-dom';
import style from './folder.module.css'
import folderIcon from './../../assets/folder.png';
import { useEffect, useRef, useState } from 'react';
import optionIcon from '../../assets/optionIcon.svg'
import IconButton from '../IconButton/iconButton';
import Modal from '../modal/modal';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Folder = ({ folder, pageName, firestore, numberOfDeletedResults, setNumberOfDeletedResults }) => {
    const { t } = useTranslation(['common']);
    
    const [optionExpanded, setOptionExpanded] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)

    const [name, setName] = useState(folder.name);

    const date = new Date(folder.timestampCreated?.seconds * 1000 ?? 0)
    const componentRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (componentRef.current && !componentRef.current.contains(e.target)) {
                setOptionExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [componentRef]);

    const updateName = () => {
        const documentRef = firestore.collection("folders").doc(folder.id);

        documentRef.update({ name: name }).then(() => {
            toast.success(t('folders.preview.toast.success'), {
                duration: 3000,
                position: 'bottom-center',
            })
        }).catch((error) => {
            toast.error(t('folders.preview.toast.error'), {
                duration: 4000,
                position: 'bottom-center',
            })
        })

        setDisplayEdit(false)

        const numberOfResultsBeforeDeletion = numberOfDeletedResults;
        setNumberOfDeletedResults(numberOfResultsBeforeDeletion + 1);
    }
    
    return (
        <div className={optionExpanded ? style.listItemSelected : style.listItem} key={folder.id}>
            <Link className={style.listItemClickableContent} to={`/${pageName}/${folder.id}`}>
                <div className={style.iconWrapper}><img className={style.icon} src={folderIcon} alt=""></img></div>
                <p className={style.defaultTextLength}>{extractFirstWords(`${folder.name}`, 80)}</p>
                <p className={style.mobileTextLength}>{extractFirstWords(`${folder.name}`, 55)}</p>
                <p className={`${style.defaultTextLength} ${style.right}`}>{date.toLocaleDateString()}</p>
            </Link>

            <div className={style.optionWrapper}>
                <button className={style.optionIconButton} onClick={() => optionExpanded ? setOptionExpanded(false) : setOptionExpanded(true)}>
                    <img className={style.optionIcon} src={optionIcon} alt="options"></img>
                </button>
                {optionExpanded && <div className={style.optionMenu} ref={componentRef}>
                    <button onClick={() => { setDisplayEdit(true); setOptionExpanded(false) }} className={style.optionMenuItem}>{t('folders.preview.option-rename')}</button>
                </div>}
            </div>

            <Modal isOpen={displayEdit} setIsOpen={() => setDisplayEdit()}>
                <h3>{t('folders.preview.rename.title')}</h3>
                <br />
                <label>{t('folders.preview.rename.label')}</label><br />
                <div className={style.inputInline}>
                    <input type="text" className={style.input} value={name} onChange={(e) => setName(e.target.value)}></input>
                    <IconButton text={t('folders.preview.rename.submit')} disabled={name.length < 2} onClick={() => updateName()} primary />
                </div>
            </Modal>
        </div>
    )
}

export default Folder;

const extractFirstWords = (text, maxChars) => {
    const length = text.split("").length;
    if (length < maxChars) return text;

    let extractedText = text.substring(0, maxChars);
    const lastSpace = extractedText.lastIndexOf(" ") + 1;
    extractedText = extractedText.substring(0, lastSpace) + "...";
    return extractedText;
};
