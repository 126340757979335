import {
  ActionIcon,
  Collapse,
  Divider,
  Flex,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";

import style from "./scan-chatbot.module.css";

import SendIcon from "./../../../../assets/sendIcon.svg";
import { useTranslation } from "react-i18next";

import star from "./../../../../assets/star.svg";

const ScanChatbot = ({
  scan,
  firebase,
  showChat,
  setShowChat,
  setTab,
  setHighlightIndex,
  setGeneratedCommentIndex,
}) => {
  const { t } = useTranslation(["common"]);

  const [message, setMessage] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const chatWrapperRef = useRef(null);

  const sendMessage = async (e, newMessage) => {
    e.preventDefault();

    if (!newMessage || newMessage.length === 0 || loading) return;

    setShowChat(true);
    setLoading(true);
    setLastMessage(newMessage);
    setMessage("");

    const functions = firebase.app().functions("europe-west1");
    const contentScan = functions.httpsCallable("chatBotMessage");

    console.log("analytics started");

    const result = await contentScan({
      message: newMessage,
      scanId: scan.contentScanId,
    });
    console.log("analytics finished");
    console.log(result);

    setLoading(false);
  };

  //Scroll to bottom:
  useEffect(() => {
    const timer = setTimeout(() => {
      // Set a delay
      if (chatWrapperRef.current) {
        const { scrollHeight, clientHeight } = chatWrapperRef.current;
        chatWrapperRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }, 100); // 200 milliseconds equals 0.2 seconds

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or the effect re-runs
  }, [scan.chatBotMessages, loading, showChat]);

  //DOM

  if (!scan) return null;

  return (
    <div className={style.wrapper}>
      {showChat && <Divider />}
      <Flex
        bg={"white"}
        style={{ cursor: "pointer" }}
        justify={"space-between"}
        align={"center"}
        onClick={() => (showChat ? setShowChat(false) : setShowChat(true))}
      >
        <Flex align={"center"} columnGap={"0.5rem"}>
          <img src={star} style={{ width: "1rem", height: "1rem" }} />
          <Title my={"0.5rem"} order={6}>
            {t("scan.copilot.title")}
          </Title>
        </Flex>
        <ActionIcon>
          {showChat ? (
            <svg
              style={{ width: "0.75rem", height: "auto" }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 105 75"
            >
              <path
                d="M104.134 22.181 52.1584 74.4694 40.8447 63.1557 104.134.180481V22.181Z"
                fill="#000"
              />
              <path
                d="M.133789.180176 63.4671 63.1534 52.1534 74.4671.133849 22.1802.133789.180176Z"
                fill="#000"
              />
            </svg>
          ) : (
            <svg
              style={{ width: "0.75rem", height: "auto" }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 105 76"
            >
              <path
                d="M.246094 53.199 52.2215.910645 63.5352 12.2244.246094 75.1995V53.199Z"
                fill="#000"
              />
              <path
                d="M104.246 75.1998 40.9128 12.2266 52.2266.912918 104.246 53.1998v22Z"
                fill="#000"
              />
            </svg>
          )}
        </ActionIcon>
      </Flex>
      <Divider />

      <Collapse in={showChat}>
        {scan.chatBotMessages?.length > 0 || loading ? (
          <div className={style.chatWrapper} ref={chatWrapperRef}>
            {scan.chatBotMessages?.map((chatBotMessage) => (
              <div>
                <div className={style.sentMessage}>
                  <Text fz={"sm"}>{chatBotMessage.requestMessage}</Text>
                </div>

                <div className={style.responseMessage}>
                  {chatBotMessage.responseFormat === 1 && (
                    <pre className={style.responseMessageText}>
                      {chatBotMessage.responseMessage}
                    </pre>
                  )}
                  {chatBotMessage.responseFormat === 2 && (
                    <p className={style.responseMessageText}>
                      {t("scan.copilot.answers.highlights.start")}
                      <span
                        className={style.chatLink}
                        onClick={() => {
                          setTab("highlights");
                          setHighlightIndex(chatBotMessage.id);
                        }}
                      >
                        {t("scan.copilot.answers.highlights.link")}
                      </span>
                      {t("scan.copilot.answers.highlights.end")}
                    </p>
                  )}
                  {chatBotMessage.responseFormat === 3 && (
                    <p className={style.responseMessageText}>
                      {t("scan.copilot.answers.comments.start")}
                      <span
                        className={style.chatLink}
                        onClick={() => {
                          setTab("generatedComments");
                          setGeneratedCommentIndex(chatBotMessage.id);
                        }}
                      >
                        {t("scan.copilot.answers.comments.link")}
                      </span>
                      {t("scan.copilot.answers.comments.end")}
                    </p>
                  )}
                </div>
              </div>
            ))}

            {loading && lastMessage && (
              <div>
                <div className={style.sentMessage}>
                  <Text fz={"sm"}>{lastMessage}</Text>
                </div>

                <div className={style.responseMessage}>
                  <Loader color="dark" variant="dots" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <Stack my={"1.5rem"} spacing={"0.5rem"}>
            <div
              className={`${style.exampleCard} plausible-event-name=copilot-suggestion-summarize `}
              onClick={(e) =>
                sendMessage(
                  e,
                  `${t(
                    "scan.copilot.example_commands.text.start"
                  )} ${getNumberOfParagraphs(scan.text)} ${t(
                    "scan.copilot.example_commands.text.end"
                  )}`
                )
              }
            >
              <span>{`${t(
                "scan.copilot.example_commands.text.start"
              )} ${getNumberOfParagraphs(scan.text)} ${t(
                "scan.copilot.example_commands.text.end"
              )}`}</span>
              <span className={style.plusIcon}>+</span>
            </div>

            <div
              className={`${style.exampleCard} plausible-event-name=copilot-suggestion-mark-techincal-terms `}
              onClick={(e) =>
                sendMessage(e, t("scan.copilot.example_commands.highlights"))
              }
            >
              <span>{t("scan.copilot.example_commands.highlights")}</span>
              <span className={style.plusIcon}>+</span>
            </div>

            <div
              className={`${style.exampleCard} plausible-event-name=copilot-suggestion-improve-language `}
              onClick={(e) =>
                sendMessage(e, t("scan.copilot.example_commands.comments"))
              }
            >
              <span>{t("scan.copilot.example_commands.comments")}</span>
              <span className={style.plusIcon}>+</span>
            </div>
          </Stack>
        )}
      </Collapse>

      <form
        className={`${style.chatInput} plausible-event-name=copilot-chat-message`}
      >
        <TextInput
          placeholder={t("scan.copilot.placeholder")}
          variant="unstyled"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={() => setShowChat(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage(e, message); // Function to call when enter is pressed
            }
          }}
        />

        <ActionIcon
          color="blue"
          onClick={(e) => sendMessage(e, message)}
          variant="subtle"
        >
          <img className={style.sendIcon} src={SendIcon}></img>
        </ActionIcon>
      </form>
    </div>
  );
};

export default ScanChatbot;

const getNumberOfParagraphs = (text) => {
  text = text.trim();
  const numberOfParagraphs = text.split(/\n\s*\n/).length;
  if (numberOfParagraphs > 5) return 5;
  else if (numberOfParagraphs < 2) return 2;
  return numberOfParagraphs;
};
