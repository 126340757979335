import { initializeApp } from "firebase/app";
import { query, where, collection, getCountFromServer, getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCg6ActQTzDtTwjSBOBx6px-TyGyZlAEnI",
    authDomain: "noai-19c19.firebaseapp.com",
    projectId: "noai-19c19",
    storageBucket: "noai-19c19.appspot.com",
    messagingSenderId: "589275210464",
    appId: "1:589275210464:web:6a7e29cb8fdd8d78f52685"
}
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const useGetNumberOfScans = () => {

    const getNumberOfScans = async (uid, user, checkAllTime) => {
        let numberOfScansLastMonth = null;

        // Calculate the 1st date of the current month
        const currentMonth = new Date();
        currentMonth.setDate(1); // Set the day of the month to 1 to get the first day of the current month

        //Query
        const coll = collection(db, "content-scan");
        // const q = checkAllTime === true ?
        //     query(coll, where('uid', '==', uid), where('version', '==', '2.1'))
        //     :
        //     query(coll, where('uid', '==', uid), where('timestampFinished', '>=', currentMonth), where('version', '==', '2.1'));

        const q = query(coll, where('uid', '==', uid), where('timestampFinished', '>=', currentMonth), where('version', '==', '2.1'));
        const snapshot = await getCountFromServer(q);
        numberOfScansLastMonth = snapshot.data().count;

        console.log(numberOfScansLastMonth)

        if (user && user.type === "trial") {
            const at = localStorage.getItem("authToken");

            if (at && Number(at) > numberOfScansLastMonth) {
                numberOfScansLastMonth = Number(at);
            }
            else if (at && Number(at) < numberOfScansLastMonth) {
                localStorage.setItem("authToken", `${numberOfScansLastMonth}`)
            }

            if (at === null || at === undefined) {
                localStorage.setItem("authToken", `${numberOfScansLastMonth}`)
            }
        }

        console.log(numberOfScansLastMonth)

        return numberOfScansLastMonth;
    }

    return { getNumberOfScans }
}