import { Button, Flex, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { functions } from "../../../App";
import { useState } from "react";



const CancelOrganizationSubscription = ({ organizationId, closeSettingsModal }) => {
    const { t } = useTranslation(['common']);

    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false);

    const cancelSubscription = () => {
        setLoading(true)

        const cancelSubscription = functions.httpsCallable('cancelOrganizationSubscription');

        cancelSubscription({ organizationId: organizationId })
            .then(result => {
                setLoading(false);
                
                window.location.href = `${window.location.origin}/organization`;
            })
            .catch(error => {
                close();
                setLoading(false);

                console.error('Error canceling subscription:', error);
            });
    }


    return (
        <>
            <Button onClick={open} color="red" variant="light">{t("organization.cancel.button")}</Button>

            <Modal
                opened={opened}
                onClose={close}
                centered
                title={<Title fw={600} order={5}>{t('organization.cancel.title')}</Title>}
            >
                <Text fz={13}>{t('organization.cancel.description')}</Text>

                <Flex columnGap={"0.5rem"} mt={"2rem"}>
                    <Button onClick={close} color="dark" variant="outline">{t("common.cancel")}</Button>

                    <Button onClick={() => cancelSubscription()} loading={loading} color="red" variant="filled">{t("organization.cancel.button")}</Button>
                </Flex>

            </Modal>
        </>
    )
}

export default CancelOrganizationSubscription;
