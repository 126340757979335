import { useEffect, useState } from "react"
import Page from "../../molecules/page/page"
import CreateUserForm from '../../organisms/create-user-form/createUserForm';
import SignInForm from '../../organisms/sign-in-form/signInForm';
import { Tabs } from '@mantine/core';

import style from './join-organization-auth.module.css'
import Spinner from "../../molecules/Spinner/spinner";
import useGetOrganizationInvitation from "../../hooks/useGetOrganizationInvitation";
import { useTranslation } from "react-i18next";

const JoinOrganizationAuth = ({ auth, firestore }) => {
    const { t } = useTranslation(['common']);

    const [loading, setLoading] = useState(true)

    const id = window.location.href.split("/")[window.location.href.split("/").length - 2]
    const { organizationInvitation } = useGetOrganizationInvitation(firestore, id);

    useEffect(() => {
        if(organizationInvitation?.id) setLoading(false)
    }, [organizationInvitation])

    if(loading) return (
        <Page>
            <div>
                <Spinner/>
            </div>
        </Page>
    )

    if(organizationInvitation) return (
        <Page>
            <div style={{maxWidth: "42rem", margin: "auto"}}>
            <div className={style.infoBox}>
                <h4>
                    {t('join-organization.auth.title')}<span style={{fontWeight: "600"}}>{organizationInvitation.data.organizationName ? organizationInvitation.data.organizationName : "Ekte.ai"}</span>
                </h4>
                <p>
                    {t('join-organization.auth.description')}
                </p>
            </div>

            <Tabs defaultValue="createUser">
                <Tabs.List position="center">
                    <Tabs.Tab value="createUser">{t('join-organization.auth.tab.create-user')}</Tabs.Tab>
                    <Tabs.Tab value="signIn">{t('join-organization.auth.tab.sign-in')}</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="createUser" pt="xs">
                    <CreateUserForm auth={auth} nextPageUrl={`/join/${id}/${organizationInvitation.data.organizationName}`}/>
                </Tabs.Panel>

                <Tabs.Panel value="signIn" pt="xs">
                    <SignInForm auth={auth} nextPageUrl={`/join/${id}/${organizationInvitation.data.organizationName}`} />
                </Tabs.Panel>
            </Tabs>
            </div>
        </Page>
    )

    return(
        <Page>
            <div>
                <span>{t('join-organization.auth.error')}</span>
            </div>
        </Page>
    )
}

export default JoinOrganizationAuth;