import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Page from '../../molecules/page/page'
import style from './dashboard.module.css'
import { Carousel } from '@mantine/carousel';

import files from './../../assets/files.png'
import questionIllustration from './../../assets/questionIllustration.png'
import precissionIllustration from './../../assets/precissionIllustration.png'
import partyPopperIllustration from './../../assets/partyPopperIllustration.png'
import agreementIllustration from './../../assets/agreementIllustration.png'

import organizationIcon from './../../assets/structure.png'
import dpa from './../../assets/dpa.png'
import ScanExamples from '../../organisms/scan-examples/scan-examples';
import { UserAndOrganzationContext } from '../../contexts/userAndOrganizationContext';
import { Flex, Image, Loader, Space, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import useGetSubscription from '../../hooks/useGetSubscription';
import sunIcon from '../../assets/sun.png';
import { useMediaQuery } from '@mantine/hooks';

const Dashboard = () => {
    const { subscriptionType, subscriptionFromOrganization } = useGetSubscription();

    const { t } = useTranslation(['dashboard']);

    const navigate = useNavigate();
    const location = useLocation();
    const { user, organization, loading } = useContext(UserAndOrganzationContext);

    const [widthBlogCarouselInvisibleFirstItem, setWidthBlogCarouselInvisibleFirstItem] = useState(0);

    useEffect(() => {
        const calculateWidth = () => {
            const newWidth = (window.innerWidth - (70 * 16)) / 2;
            setWidthBlogCarouselInvisibleFirstItem(newWidth);
        };

        calculateWidth();

        window.addEventListener('resize', calculateWidth);
        return () => {
            window.removeEventListener('resize', calculateWidth);
        };
    }, []);


    //Scroll to result-confidence if anchor is in url
    useEffect(() => {
        const element = document.getElementById('result_confidence');

        if (location.hash === "#result_confidence") {
            element.scrollIntoView()
        }
    })

    useEffect(() => {
        if (subscriptionType && !subscriptionFromOrganization && subscriptionType === "waiting") {
            navigate("/waitlist");
        }
        if (subscriptionType && !subscriptionFromOrganization && subscriptionType === "cancelled") {
            navigate("/upgrade");
        }
    }, [subscriptionType])

    const isToday = (dateRaw) => {
        if (!dateRaw) return false;

        const today = new Date();
        const date = new Date(dateRaw?.seconds * 1000);

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }


    const isMobile = useMediaQuery("(max-width: 800px)");

    const firstTime = user && isToday(user?.userData?.createdDate);

    if (loading) return (
        <Page grey>
            <div style={{ width: "fit-content", marign: "auto", marginTop: "10rem", textAlign: "center" }}>
                <Loader size="lg" variant='oval'></Loader>
            </div>
        </Page>
    )

    return (
        <>
            <Page grey>
                <div className={style.contentWrapper}>
                    <section className={style.actionSection}>
                        <div>
                            {/* <h1 className={style.title}>{firstTime ? t('intro.title-first-time') : t('intro.title-summer')}</h1> */}
                            {!firstTime && <Flex h={"2rem"} align={"center"} columnGap={"0.5rem"}>
                                <Image height={isMobile ? "1.25rem" : "1.5rem"} width={"auto"} src={sunIcon} />
                                <Title fw={600} fz={isMobile ? "1rem" : "1.25rem"}>{t('intro.title-prequel-summer')}</Title>
                            </Flex>}

                            <Title w={isMobile ? "auto" : 450} mt={"1rem"} mb={"1.5rem"} fw={600} fz={isMobile ? "1.5rem" : "2rem"}>{firstTime ? t('intro.title-first-time') : t('intro.title-summer')}</Title>

                            <p className={style.p}>
                                {firstTime ? t('intro.description-first-time')
                                    : <><span>{`${t('intro.description-summer')} `}</span><Link to={"/upgrade"}><span style={{textDecoration: "underline"}}>{t("intro.description-summer-read-more")}</span></Link></>}
                            </p>
                        </div>
                        {/*"Nyhet: Nå kan du få smarte forslag til oppfølgingsspørsmål basert på en tekst. Det er også mulig å redigere eller fjerne forslag, eller legge til dine egne oppfølgingsspørsmål. Du finner det inne på tekster som allerede har blitt sjekket for AI."*/}

                        <div className={style.actionButtonGrid}>
                            <Link to="/check-ai">
                                <div className={`${style.actionButton} ${style.actionButtonBlack}`}>
                                    {/* <svg className={style.actionButtonIcon} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><circle cx="64" cy="64" r="64" fill="url(#a)"/><rect x="60" y="32" width="8" height="64" rx="4" fill="#fff"/><rect x="32" y="68" width="8" height="64" rx="4" transform="rotate(-90 32 68)" fill="#fff"/><defs><linearGradient id="a" x1="18" y1="19" x2="106" y2="113" gradientUnits="userSpaceOnUse"><stop stop-color="#30F"/><stop offset="1" stop-color="#DB00FF"/></linearGradient></defs></svg> */}
                                    <svg className={style.actionButtonIcon} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><circle cx="64" cy="64" r="64" fill="url(#a)" /><rect x="73" y="77.5355" width="5" height="38.5137" rx="2.5" transform="rotate(-45 73 77.5355)" fill="#fff" /><rect x="54" y="38" width="3" height="32" rx="1.5" fill="#fff" /><rect x="40" y="56" width="3" height="32" rx="1.5" transform="rotate(-90 40 56)" fill="#fff" /><circle cx="56" cy="54" r="29.5" stroke="#fff" stroke-width="5" /><defs><linearGradient id="a" x1="18" y1="19" x2="106" y2="113" gradientUnits="userSpaceOnUse"><stop stop-color="#30F" /><stop offset="1" stop-color="#DB00FF" /></linearGradient></defs></svg>
                                    <div className={style.actionButtonText}>
                                        <h4 className={style.actionButtonTitle}>{t('actions.check-ai.title')}</h4>
                                        <p className={style.actionButtonDescription}>{t('actions.check-ai.description')}</p>
                                    </div>
                                    <svg className={style.chevron} fill="#none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 128"><path d="m23.001 12 52.2884 51.9754-11.3137 11.3137L1.00049 12H23.001Z" fill="#FFF" /><path d="M1.00037 116 63.9736 52.6668l11.3137 11.3137L23.0004 116H1.00037Z" fill="#FFF" /></svg>
                                </div>
                            </Link>
                            <Link to="/new-scan">
                                <div className={style.actionButton}>
                                    <svg className={style.actionButtonIcon} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><circle cx="64" cy="64" r="64" fill="url(#a)" /><rect x="60" y="32" width="8" height="64" rx="4" fill="#fff" /><rect x="32" y="68" width="8" height="64" rx="4" transform="rotate(-90 32 68)" fill="#fff" /><defs><linearGradient id="a" x1="18" y1="19" x2="106" y2="113" gradientUnits="userSpaceOnUse"><stop stop-color="#30F" /><stop offset="1" stop-color="#DB00FF" /></linearGradient></defs></svg>
                                    {/* <svg className={style.actionButtonIcon} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><circle cx="64" cy="64" r="64" fill="url(#a)" /><rect x="73" y="77.5355" width="5" height="38.5137" rx="2.5" transform="rotate(-45 73 77.5355)" fill="#fff" /><rect x="54" y="38" width="3" height="32" rx="1.5" fill="#fff" /><rect x="40" y="56" width="3" height="32" rx="1.5" transform="rotate(-90 40 56)" fill="#fff" /><circle cx="56" cy="54" r="29.5" stroke="#fff" stroke-width="5" /><defs><linearGradient id="a" x1="18" y1="19" x2="106" y2="113" gradientUnits="userSpaceOnUse"><stop stop-color="#30F" /><stop offset="1" stop-color="#DB00FF" /></linearGradient></defs></svg> */}
                                    <div className={style.actionButtonText}>
                                        <h4 className={style.actionButtonTitle}>{t('actions.new-scan.title')}</h4>
                                        <p className={style.actionButtonDescription}>{t('actions.new-scan.description')}</p>
                                    </div>
                                    <svg className={style.chevron} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 128"><path d="m23.001 12 52.2884 51.9754-11.3137 11.3137L1.00049 12H23.001Z" fill="#000" /><path d="M1.00037 116 63.9736 52.6668l11.3137 11.3137L23.0004 116H1.00037Z" fill="#000" /></svg>
                                </div>
                            </Link>
                            <Link to="/scans">
                                <div className={style.actionButton}>
                                    <img className={style.actionButtonIcon} src={files}></img>
                                    <div className={style.actionButtonText}>
                                        <h4 className={style.actionButtonTitle}>{t('actions.scans.title')}</h4>
                                        <p className={style.actionButtonDescription}>{t('actions.scans.description')}</p>
                                    </div>
                                    <svg className={style.chevron} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 128"><path d="m23.001 12 52.2884 51.9754-11.3137 11.3137L1.00049 12H23.001Z" fill="#000" /><path d="M1.00037 116 63.9736 52.6668l11.3137 11.3137L23.0004 116H1.00037Z" fill="#000" /></svg>
                                </div>
                            </Link>
                            {/* <Link to={organization?.organizationId ? "/organization" : "/upgrade"}>
                                <div className={style.actionButton}>
                                    <img className={style.actionButtonIcon} src={organizationIcon}></img>
                                    <div className={style.actionButtonText}>
                                        <h4 className={style.actionButtonTitle}>{organization?.organizationId ? "Min organisasjon" : "Opprett organisasjon"}</h4>
                                        <p className={style.actionButtonDescription}>Administrer medlemmer og lisenser</p>
                                    </div>
                                    <svg className={style.chevron} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 128"><path d="m23.001 12 52.2884 51.9754-11.3137 11.3137L1.00049 12H23.001Z" fill="#000" /><path d="M1.00037 116 63.9736 52.6668l11.3137 11.3137L23.0004 116H1.00037Z" fill="#000" /></svg>
                                </div>
                            </Link> */}
                            {/* <Link to={organization?.organizationId ? `/DPA/${organization.organizationId}` : "/upgrade"}>
                                <div className={style.actionButton}>
                                    <img className={style.actionButtonIcon} src={dpa}></img>
                                    <div className={style.actionButtonText}>
                                        <h4 className={style.actionButtonTitle}>{t('actions.dpa.title')}</h4>
                                        <p className={style.actionButtonDescription}>{t('actions.dpa.description')}</p>
                                    </div>
                                    <svg className={style.chevron} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 128"><path d="m23.001 12 52.2884 51.9754-11.3137 11.3137L1.00049 12H23.001Z" fill="#000" /><path d="M1.00037 116 63.9736 52.6668l11.3137 11.3137L23.0004 116H1.00037Z" fill="#000" /></svg>
                                </div>
                            </Link> */}
                        </div>
                    </section>

                    {/* <stripe-pricing-table pricing-table-id="prctbl_1NTP2FKwREFQyFmXOnD92PbV"
                        publishable-key="pk_test_51NTOXIKwREFQyFmXG24JjmvYtcJcNU7dQuGHQkF8dkQV4EEBoxtQeOWHe0lyvufDpSqfL2GKpmqIrRyrabkiNqMZ00gdtRstIe">
                    </stripe-pricing-table> */}

                    {/* <section className={style.blogSection}>
                        <h2>Nyheter</h2>
                        <div className={style.carouselWrapper}>
                            <Carousel
                                slideSize="25rem"
                                height={"32rem"}
                                align="start"
                                slideGap="xs"
                                controlsOffset="xl"
                                controlSize={40}
                                styles={{
                                    control: {
                                        '&[data-inactive]': {
                                            opacity: 0,
                                            cursor: 'default',
                                        },
                                    },
                                }}>
                                <Carousel.Slide size={widthBlogCarouselInvisibleFirstItem}>
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <div className={style.blogCard} style={{ backgroundColor: "black" }}>
                                        <Text fz={"14px"} mb={"0.3rem"} color={"#cfcfcf"}>Nyhet</Text>
                                        <h3 style={{ margin: 0, marginBottom: "0.6rem", fontSize: "28px", fontWeight: 500, color: "white" }}>Oppfølgingsspørsmål?</h3>
                                        <Text fz={"16px"} color='white'>{"Få smarte forslag til oppfølgingsspørsmål\n eller lag dine egne."}</Text>

                                        <div className={style.blogCardBottomImageWrapper}>
                                            <img src={questionIllustration} alt=""></img>
                                        </div>
                                    </div>
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <div className={`${style.blogCard} ${style.blogCardPrecissionIllustrationBackground}`}>
                                        <Text fz={"14px"} mb={"0.3rem"} color={"dark"}>Oppdatering</Text>
                                        <h3 style={{ margin: 0, marginBottom: "0.6rem", fontSize: "28px", fontWeight: 600 }}>Mer presis. <br />Mer åpenhet.</h3>
                                        <Text fz={"16px"}>Enda færre falske positiver.</Text>
                                    </div>
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <div className={style.blogCard}>
                                        <Text fz={"14px"} mb={"0.3rem"} color={"dark"}>Milepæl</Text>
                                        <h3 style={{ margin: 0, marginBottom: "0.6rem", fontSize: "28px", fontWeight: 600 }}>{"Mer enn 1000 lærere har tatt i bruk Ekte.ai"}</h3>
                                        <Text fz={"16px"}>24.09.2023</Text>

                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            <img className={style.blogCardImage} src={partyPopperIllustration} alt=""></img>
                                        </div>
                                    </div> 
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <div className={style.blogCard}>
                                        <Text fz={"14px"} mb={"0.3rem"} color={"dark"}>Nyhet</Text>
                                        <h3 style={{ margin: 0, marginBottom: "0.6rem", fontSize: "28px", fontWeight: 600 }}>Databehandleravtaler</h3>
                                        <Text fz={"16px"}>Nå kan du inngå en databehandleravtale på vegne av din organisasjon.</Text>
                                    </div>
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <div className={`${style.blogCard} ${style.blogCardAgreementIllustrationBackground}`} style={{ backgroundColor: "black" }}>
                                        <Text fz={"14px"} mb={"0.3rem"} color={"#cfcfcf"}>Nyhet</Text>
                                        <h3 style={{ margin: 0, marginBottom: "0.6rem", fontSize: "28px", fontWeight: 500, color: "white" }}>Databehandleravtaler</h3>
                                        <Text fz={"16px"} color='white'>{"Nå kan du inngå en databehandleravtale på vegne av din organisasjon."}</Text>
                                    </div>
                                </Carousel.Slide>
                            </Carousel>
                        </div>
                    </section> */}

                    {/* <section className={style.blogSection}>
                        <h2>Ressurser</h2>
                        <p>
                            Ekte deler opp teksten i bruddstykker og analyserer hver for seg. Den klassifiserer da
                            teksten som en av de tre alternativene under:
                        </p>

                        <InfoSlab aiScore={0.9994} />
                    </section> */}



                    {/* <div className={style.contentGroup}>
                    <div className={style.actionGroup}>
                        <div className={style.actionItem}>
                            <IconButton className={style.dd} primary text={"Sjekk tekst →"} onClick={() => navigate("/new-scan")} />
                        </div>
                        <div className={style.actionItem}>
                            <IconButton className={style.dd} primary text={"Sjekk filer →"} onClick={() => navigate("/new-file-scan")}/>
                        </div>
                    </div>
                </div> */}

                </div>

            </Page>
            <Page>
                <section className={style.tutorialWrapper}>
                    <h2>{t('how.title')}</h2>
                    <p>{t('how.description')}</p>
                    <ul>
                        <li>{t('how.classifications.ai')}</li>
                        <li>{t('how.classifications.unsure')}</li>
                        <li>{t('how.classifications.probably-not')}</li>
                        <li>{t('how.classifications.original')}</li>
                    </ul>
                    <br />

                    <h4>{t('how.examples-title')}</h4>
                    <ScanExamples />
                </section>
            </Page>
            <Page>
                <section id="result_confidence" className={`${style.tutorialWrapper} ${style.centered}`}>
                    <h2>{t('handling-ai.title')}</h2>
                    <p>{t('handling-ai.description')} </p>
                    <ul>
                        <li><span style={{ fontWeight: "bold" }}>{t('handling-ai.characteristics.format.title')}</span>{` - ${t('handling-ai.characteristics.format.description')}`}</li>
                        <br />
                        <li><span style={{ fontWeight: "bold" }}>{t('handling-ai.characteristics.scope.title')}</span>{` - ${t('handling-ai.characteristics.scope.description')}`}</li>
                        <br />
                        <li><span style={{ fontWeight: "bold" }}>{t('handling-ai.characteristics.scope-accross-texts.title')}</span>{` - ${t('handling-ai.characteristics.scope-accross-texts.description')}`}</li>
                    </ul>
                </section>
            </Page>
        </>
    )
}

export default Dashboard;
