import { Button, Divider, Flex, Modal, NumberInput, Stack, Text, Title } from "@mantine/core";
import { useState } from "react";
import { functions } from "../../../App";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";

const BuySubscription = ({ organizationId, organizationData }) => {
    const { t } = useTranslation(['common']);

    const [opened, { open, close }] = useDisclosure(false);
    const [numberOfUsers, setNumberOfUsers] = useState(5);
    const [loading, setLoading] = useState(false);

    const priceId = "price_1PUQYPKwREFQyFmXZyoWuHB5"; //Premium for Schools

    const isTrial = organizationData.subscription === "cancelled" ? false : true;

    const createCheckoutSession = async () => {
        setLoading(true);

        if (numberOfUsers < 5 || numberOfUsers > 55) return;

        const createCheckoutSessionQuery = functions.httpsCallable('checkoutSession');
        const hostname = window.location.hostname;


        const stripe = await loadStripe('pk_live_51NTOXIKwREFQyFmXRZDU7ohPjdtDGzYxjN4rW7VsQRP5GUjFss8ljK9He5LAIkmcvFU7KfEYFai3QZWtKABUPhlu000FG2ds5E');

        try {
            const result = await createCheckoutSessionQuery({ priceId: priceId, isTrial, hostname, organizationId, quantity: numberOfUsers });
            const sessionId = result.data.sessionId;
            const { error } = await stripe.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }

        setLoading(false);
    };

    const pricePerUser = Number.parseInt(t("upgrade.schools.price-per-user")) ?? 50;


    return (
        <>
            <Button color="dark" variant="filled" onClick={open}>{isTrial ? t("organization.start-trial.button") : t('organization.buy-subscription.title')}</Button>

            <Modal
                opened={opened}
                onClose={close}
                centered
                title={<Title fw={600} order={5}>{isTrial ? t("organization.start-trial.title") : t('organization.buy-subscription.title')}</Title>}
            >


                <Stack spacing={"0.5rem"} mt={"1rem"} mb={"2rem"}>
                    <label>{isTrial ? t("organization.start-trial.label") : t('organization.buy-subscription.label')}</label>
                    <NumberInput
                        size="md"
                        maw={"15rem"}
                        value={numberOfUsers}
                        onChange={setNumberOfUsers}
                        min={5}
                        max={55}
                        step={1}
                        onBlur={() => { !numberOfUsers && setNumberOfUsers(organizationData.numberOfUserLicences ?? undefined) }}
                    />
                </Stack>

                <Divider />

                {isTrial ?
                    <Stack spacing={"0rem"} my={"1rem"}>
                        <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.schools.trial-title")}</Text>

                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.schools.trial-total-price", { price: pricePerUser * numberOfUsers })}</Text>
                    </Stack>
                    :
                    <Stack spacing={"0rem"} my={"1rem"}>
                        <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.schools.total-price", { price: pricePerUser * numberOfUsers })}</Text>
                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.schools.billing-frequency-total")}</Text>
                    </Stack>
                }

                <Flex columnGap={"0.5rem"} mt={"1.5rem"}>
                    <Button onClick={close} variant="outline" color="dark">{t("common.cancel")}</Button>

                    <Button color="dark" loading={loading} onClick={() => createCheckoutSession()} disabled={!numberOfUsers || numberOfUsers === organizationData.numberOfUserLicences}>{isTrial ? t("organization.start-trial.button") : t("organization.buy-subscription.button")}</Button>
                </Flex>

            </Modal>
        </>
    )
}

export default BuySubscription;
