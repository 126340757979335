import IconButton from '../../molecules/IconButton/iconButton';

import style from './create-user.module.css'
import { useNavigate } from 'react-router-dom';
import CreateUserForm from '../../organisms/create-user-form/createUserForm';
import Page from '../../molecules/page/page';
import { useTranslation } from 'react-i18next';

const CreateUser = ({ auth }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();

    return (
        <div className={style.signInWrapper}>
            <div className={style.mainWrapper}>
                <Page>
                    <CreateUserForm auth={auth} nextPageUrl={"/"} />
                </Page>
            </div>
            <div className={style.signUpNavWrapper}>
                <div className={style.signUpNavContent}>

                    <h1 className={style.h1Inverted}>{t('auth.create-user.page.title')}</h1>
                    <p className={style.h1Inverted}>{t('auth.create-user.page.description')}</p>
                    <div className={style.signUpNavButton}>
                        <IconButton outlined text={t('auth.create-user.page.switch-to-create-user-button')} onClick={() => navigate("/sign-in")} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CreateUser;