import { Button, Card, Divider, Flex, Image, Modal, Stack, Text, TextInput, Title, UnstyledButton } from "@mantine/core";
import Page from "../../molecules/page/page";
import { useDisclosure } from "@mantine/hooks";
import IconButton from "../../molecules/IconButton/iconButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import GoogleUserIcon from "./../../assets/googleUserLogo.png";
import EditIcon from "./../../assets/edit.png";
import AddIcon from "./../../assets/plus.png";
import { functions } from "../../App";
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";


const ConnectUser = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['common']);
    const { user, organization } = useContext(UserAndOrganzationContext);

    const [opened, { open, close }] = useDisclosure(false);

    const [googleDocsEmail, setGoogleDocsEmail] = useState(user?.userData?.googleDocsUserEmail ?? "");
    const [loading, setLoading] = useState(false);

    const connectToExternalUser = functions.httpsCallable("connectToExternalUser")

    const connectToExternalUserHandler = async (e) => {
        e.preventDefault();
        if (!googleDocsEmail || googleDocsEmail.length < 5) return;

        setLoading(true);

        try {
            const result = await connectToExternalUser({ externalUserEmail: googleDocsEmail });
            //const data = result.data;
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

        close();
        setLoading(false);
    }

    useEffect(() => {
        if (googleDocsEmail === "" && user?.userData?.googleDocsUserEmail) {
            setGoogleDocsEmail(user.userData.googleDocsUserEmail);
        }
    }, [user])

    return (
        <Page>
            <div style={{ width: "fit-content" }}>
                <IconButton text={`← ${t('common.back')}`} onClick={() => navigate(-1)} />
            </div>

            <Stack spacing={0} m={"auto"} maw={330} mt={{ base: "3rem", md: "5rem" }} ta={"center"}>
                <h4>{t("user.connect-user.title")}</h4>
                <Divider mb={"2rem"} />

                <UnstyledButton onClick={open}>
                    <Card shadow="sm" withBorder>
                        {user?.userData?.googleDocsUserEmail ?
                            <Flex justify={"space-between"}>
                                <Flex columnGap={"1rem"} align={"flex-start"}>
                                    <Image src={GoogleUserIcon} width={"auto"} height={32} mt={"0.25rem"} />
                                    <Stack spacing={"0.25rem"}>
                                        <Title fz={16}>{t("user.connect-user.google-docs.preview-title")}</Title>
                                        <Text fz={14} maw={200} >{user.userData.googleDocsUserEmail}</Text>
                                    </Stack>
                                </Flex>
                                <Image src={EditIcon} width={"auto"} height={13} />
                            </Flex>
                            :
                            <Flex justify={"space-between"}>
                                <Flex columnGap={"1rem"} align={"flex-start"}>
                                    <Image src={GoogleUserIcon} width={"auto"} height={32} mt={"0.25rem"} />
                                    <Stack spacing={"0.25rem"}>
                                        <Title fz={16}>{t("user.connect-user.google-docs.preview-title")}</Title>
                                        <Text fz={14} maw={200} >{t("user.connect-user.google-docs.preview-description")}</Text>
                                    </Stack>
                                </Flex>
                                <Image src={AddIcon} width={"auto"} height={13} />
                            </Flex>
                        }
                    </Card>
                </UnstyledButton>
            </Stack>

            <Modal opened={opened} onClose={close} title={t("user.connect-user.google-docs.title")} centered>
                <form onSubmit={(e) => connectToExternalUserHandler(e)}>
                    <Text fz={14} mb={"2rem"}>{t("user.connect-user.google-docs.description")}</Text>

                    <TextInput label={t("user.connect-user.google-docs.email")} value={googleDocsEmail} onChange={(e) => setGoogleDocsEmail(e.currentTarget.value)} placeholder={`${t("user.connect-user.google-docs.email")}...`} mt={"1rem"} />

                    <Flex columnGap={"0.5rem"} mt={"2rem"}>
                        <Button type="submit" color="dark" variant="filled" loading={loading} disabled={!googleDocsEmail || googleDocsEmail.length < 5}>{t("user.connect-user.google-docs.connect")}</Button>
                        <Button onClick={close} color="dark" variant="outline">{t("common.cancel")}</Button>
                    </Flex>
                </form>
            </Modal>
        </Page>
    )
}

export default ConnectUser;
