import React from 'react';
import style from './iconButton.module.css';


const IconButton = ({ icon, altText, text, onClick, className, primary=false, outlined=false, outlinedPrimary=false, primaryBlue=false, rounded=false, negative=false, disabled=false, left=false }) => {

    const config = [
        {
            type: "primary",
            wrapper: style.wrapperPrimary,
            icon: style.iconPrimary,
            text: style.textPrimary,
            wrapperDisabled: style.wrapperDisabled,
            textDisabled: style.textDisabled,
        },
        {
            type: "outlined",
            wrapper: style.wrapperOutlined,
            icon: style.iconOutlined,
            text: style.textOutlined,
            wrapperDisabled: style.wrapperOutlined,
            textDisabled: style.textDisabled,
        },
        {
            type: "none",
            wrapper: "",
            icon: "",
            text: "",
            wrapperDisabled: style.wrapperDisabled,
            textDisabled: style.textDisabled,
        },
        {
            type: "negative",
            wrapper: style.wrapperNegative,
            icon: style.iconOutlined,
            text: style.textOutlined,
            wrapperDisabled: style.wrapperDisabled,
            textDisabled: style.textDisabled,
        },
        {
            type: "rounded",
            wrapper: style.wrapperRounded,
            icon: style.iconRounded,
            text: style.textRounded,
            wrapperDisabled: style.wrapperDisabledRounded,
            textDisabled: style.textDisabledRounded,
        },
        {
            type: "outlinedPrimary",
            wrapper: style.wrapperOutlinedPrimary,
            icon: style.iconOutlined,
            text: style.textOutlined,
            wrapperDisabled: style.wrapperDisabled,
            textDisabled: style.textDisabled,
        },
        {
            type: "primaryBlue",
            wrapper: style.wrapperPrimaryBlue,
            icon: style.iconOutlined,
            text: style.textPrimaryBlue,
            wrapperDisabled: style.wrapperDisabled,
            textDisabled: style.textDisabled,
        },
    ];

    const getStyle = () => {
        if (primary) return getConfigItem("primary")
        if (outlined) return getConfigItem("outlined")
        if (outlinedPrimary) return getConfigItem("outlinedPrimary")
        if (primaryBlue) return getConfigItem("primaryBlue")
        if (negative) return getConfigItem("negative")
        if (rounded) return getConfigItem("rounded")
        return getConfigItem("none")
    }

    const getConfigItem = (type) => {
        return config.find((configItem) => configItem.type === type)
    }

    const buttonStyle = getStyle();

    return (
        <button
            className={`${style.wrapper} ${disabled ? buttonStyle.wrapperDisabled : buttonStyle.wrapper} ${className} ${left ? style.left : style.center}`}
            onClick={(e) => { !disabled && onClick(e) }}>
            {icon ? <img className={`${style.icon} ${buttonStyle.icon}`} src={icon} alt={altText ? altText : ""} /> : <span />}
            {text !== undefined && <span className={`${style.text} ${disabled ? buttonStyle.textDisabled : buttonStyle.text}`}>{text}</span>}
        </button>
    )
}

export default IconButton