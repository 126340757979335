import React, { createContext, useEffect, useState } from 'react';
import useGetUserAndOrganization from '../hooks/useGetUserAndOrganization';
import { useCollection } from 'react-firebase-hooks/firestore';

// Create context
const UserAndOrganzationContext = createContext();

// Custom provider component
const UserAndOrganzationContextProvider = ({ firestore, auth, children }) => {

  const [user, setUser] = useState({
    userDocumentId: "",
    userData: null,
  });

  const [organization, setOrganization] = useState({
    organizationId: "",
    organizationData: null,
  });

  const [loading, setLoading] = useState(true);

  //Query for user-document
  const collectionRef = firestore.collection('users');
  const userQuery = collectionRef.where('userID', '==', auth.currentUser.uid).limit(1);
  const [value, error] = useCollection(userQuery);

  const queryForOrganization = async (userData) => {
    const organizationQuery = firestore.collection('organizations').doc(userData.organization);
    const organizationDoc = await organizationQuery.get();

    if (organizationDoc.exists) {
      setOrganization({
        organizationId: organizationDoc.id,
        organizationData: organizationDoc.data(),
      })
    }
  }

  useEffect(() => {
    let userData = null

    if (value?.docs && value.docs.length > 0) {
      setUser({
        userDocumentId: value.docs[0].id,
        userData: value.docs[0].data()
      });

      userData = value.docs[0].data();
    }

    //Conditional query for organization
    if (userData?.organization !== null && userData?.organization !== undefined && userData?.organization !== "") {
      try {
        queryForOrganization(userData);
      }
      catch (error) {
        console.log(error.message)
      }

    }

    setLoading(false)
  }, [value])

  // Provide the state and functions to the children components
  return (
    <UserAndOrganzationContext.Provider value={{ user, organization, loading }}>
      {children}
    </UserAndOrganzationContext.Provider>
  );
};

export { UserAndOrganzationContext, UserAndOrganzationContextProvider };