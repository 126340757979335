import { Link, useLocation, useNavigate } from "react-router-dom";
import Page from "../../molecules/page/page";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import style from './scans.module.css'
import { useEffect, useRef, useState } from "react";
import IconButton from "../../molecules/IconButton/iconButton";
import Folder from "../../molecules/folder/folder";
import CreateFolder from "../../molecules/createFolder/createFolder";

import arrowRight from '../../assets/right-arrow.png'
import arrowLeft from '../../assets/left-arrow.png'
import ScanListItem from "../../molecules/scanListItem/scanListItem";
import Spinner from "../../molecules/Spinner/spinner";
import { useTranslation } from "react-i18next";

const Scans = ({ auth, firestore }) => {
    const { t } = useTranslation(['common']);

    const folderIdUnchecked = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const folderId = folderIdUnchecked === "scans" ? null : folderIdUnchecked;

    const [displayCreateFolder, setDisplayCreateFolder] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null)
    const [folders, setFolders] = useState([])
    const [scans, setScans] = useState([])

    const [loadingScans, setloadingScans] = useState(false)
    const [loadingFolders, setloadingFolders] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();

    const ref = useRef(null);
    const RESULT_COUNT = 35;
    const [numberOfResults, setNumberOfResults] = useState(RESULT_COUNT)
    const [numberOfDeletedResults, setNumberOfDeletedResults] = useState(0)

    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

    useEffect(() => {
        setShouldScrollToBottom(false)

        const getFolders = async () => {
            setloadingFolders(true);

            const folders = []
            const foldersRef = firestore.collection("folders")
            folderId ?
                await foldersRef.where("uid", "==", auth.currentUser.uid).where("parentId", "==", folderId).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
                :
                await foldersRef.where("uid", "==", auth.currentUser.uid).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })

            setFolders(folders)
            setloadingFolders(false)
        }

        const getCurrentFolder = async () => {
            try {
                const currentFolderRef = firestore.collection("folders").doc(folderId)
                const documentSnapshot = await currentFolderRef.get();
                if (documentSnapshot) {
                    setCurrentFolder({ id: documentSnapshot.id, ...documentSnapshot.data() })
                }
                else console.error("Error: Document not found")

            } catch (error) {
                console.error('Error fetching document data:', error);
            }
        }

        const getScans = async () => {
            setloadingScans(true)

            const scans = []
            const scansRef = firestore.collection("content-scan")
            folderId ?
                await scansRef.where("uid", "==", auth.currentUser.uid).where("parentId", "==", folderId).orderBy('timestampFinished', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().deleted !== true) scans.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
                :
                await scansRef.where("uid", "==", auth.currentUser.uid).where("parentId", "==", null).orderBy('timestampFinished', 'desc').limit(numberOfResults).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().deleted !== true) scans.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })

            setScans(scans)
            setloadingScans(false)
        }

        getFolders();
        folderId && getCurrentFolder();
        getScans();
    }, [location, displayCreateFolder, numberOfResults, numberOfDeletedResults])

    const increaseNumberOfResults = () => {
        setShouldScrollToBottom(true)
        const i = numberOfResults + RESULT_COUNT;
        setNumberOfResults(i)
    }

    useEffect(() => {
        shouldScrollToBottom && ref.current.scrollIntoView({ behavior: "smooth" })
    }, [scans])

    return (
        <Page>
            <h1>{t('scans.title')}</h1>

            <p>{`${t('folders.current-folder-label')}: ${folderId && currentFolder?.path ? currentFolder.path : t('folders.main-folder')}`}</p>
            <div className={style.listHeader}>
                <div>
                    <button className={folderId ? style.listHeaderIconButton : style.listHeaderIconButtonDisabled} onClick={() => currentFolder?.parentId ? navigate(`/scans/${currentFolder.parentId}`) : navigate("/scans")}><img src={arrowLeft} alt="Disabled button forward" className={style.arrowIcon}></img></button>
                    <button className={style.listHeaderIconButtonDisabled} ><img src={arrowRight} alt="Disabled button forward" className={style.arrowIcon}></img></button>
                </div>
                <Link className={style.listHeaderButtonPC} to={`/new-scan${folderId ? `/${folderId}` : ""}`}><p>{`+ ${t('scans.scan-text-here')}`}</p></Link>
                <button className={style.listHeaderButton} onClick={() => displayCreateFolder ? setDisplayCreateFolder(false) : setDisplayCreateFolder(true)}>{`+ ${t('folders.create-new-folder-button')}`}</button>
            </div>

            <CreateFolder auth={auth} firestore={firestore} folderId={folderId} currentFolder={currentFolder} display={displayCreateFolder} setDisplay={setDisplayCreateFolder} />

            {folders && folders.length > 0 && folders.map((folder) => {
                if ((!folderId && folder.parentId) || (folderId && folder.parentId !== folderId)) return null;
                return (
                    <Folder folder={folder} pageName="scans" firestore={firestore} numberOfDeletedResults={numberOfDeletedResults} setNumberOfDeletedResults={setNumberOfDeletedResults} />
                )
            })}

            {scans && scans.length > 0 && scans.map((scan, index) => {
                if ((!folderId && scan.parentId) || (folderId && scan.parentId !== folderId)) return null
                return (
                    <ScanListItem scan={scan} firestore={firestore} numberOfDeletedResults={numberOfDeletedResults} setNumberOfDeletedResults={setNumberOfDeletedResults} />
                )
            }
            )}
            <div style={{ marginTop: "1rem", height: "2rem" }}>
                {loadingScans || loadingFolders ? <Spinner /> : <span />}
            </div>
            {scans && scans.length < 1 && !folderId && !loadingScans && !loadingFolders && <p>{t('scans.no-results')}</p>}

            <div className={style.showMore} ref={ref}>
                <IconButton text={`${t('scans.see-more-scans-button')} ↓`} outlined onClick={() => increaseNumberOfResults()} />
            </div>
        </Page>
    )
}

export default Scans;
