import { ActionIcon, Button, Card, Flex, Input, Textarea } from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";

import style from './comments.module.css';

import addCommentIcon from './../../../../assets/add-comment.png'
import optionIcon from './../../../../assets/optionIcon.svg'
import GeneralFeedback from "../../../../organisms/general-feedback/general-feedback";
import { UserAndOrganzationContext } from "../../../../contexts/userAndOrganizationContext";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import ExportScan from "../../../../organisms/export-scan/export-scan";

const CommentsTab = ({ firebase, auth, firestore, scan, contentScanId, scrollWrapperRef }) => {
    const { t } = useTranslation(['common']);

    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to update the scroll position state
    const handleScroll = () => {
        const position = scrollWrapperRef.current.scrollTop;
        setScrollPosition(position);
    };

    // Use effect to add and remove the event listener
    useEffect(() => {
        const divElement = scrollWrapperRef.current;
        divElement.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            divElement.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const { user, organization } = useContext(UserAndOrganzationContext);

    const preRef = useRef(null);
    const commentsRef = useRef(null);
    const addCommentButtonRef = useRef(null);

    const [selectedText, setSelectedText] = useState("");
    const [newCommentText, setNewCommentText] = useState("");

    const [editedCommentIndex, setEditedCommentIndex] = useState(null);
    const [displayCommentForm, setDisplayCommentForm] = useState(false);
    const [commentFormRelativeY, setCommentFormRelativeY] = useState(0)

    const [indexOfHoveredSentence, setIndexOfHoveredSentence] = useState(null);


    const [elements, setElements] = useState([]);
    const [commentElements, setCommentElements] = useState([]);

    const [startIndex, setStartIndex] = useState(null);
    const [endIndex, setEndIndex] = useState(null);

    const [showGuide, setShowGuide] = useState(false);

    useEffect(() => {
        const hasSeenGuide = localStorage.getItem("hasSeenCommentGuide")

        if (hasSeenGuide === null || hasSeenGuide === undefined) setShowGuide(true)
    }, [])

    const handleTextSelection = (selection) => {
        const selectedText = selection.toString();

        if (!displayCommentForm) {
            if (selectedText === "") {
                setSelectedText("");
            } else if (isPartOfText(selectedText)) {
                setSelectedText(selectedText);
                updateSelectionIndexes(selection);
                getSelectedTextCoordinates();
            }
        }
    };

    const updateSelectionIndexes = () => {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(document.getElementById('text-container'));
            preCaretRange.setEnd(range.startContainer, range.startOffset);
            const start = preCaretRange.toString().length;

            preCaretRange.setEnd(range.endContainer, range.endOffset);
            const end = preCaretRange.toString().length;

            setStartIndex(start);
            setEndIndex(end);
        }
    };

    const isPartOfText = (string) => {
        return scan.text.includes(string);
    }

    const addComment = async () => {
        const copyOfComments = [...scan.comments];

        copyOfComments.push({
            relevantText: selectedText,
            comment: newCommentText,
            startIndex: startIndex,
            endIndex: endIndex,
            authorName: auth.currentUser.displayName,
            authorEmail: auth.currentUser.email,
        })

        setDisplayCommentForm(false);

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            comments: copyOfComments,
        })

        setNewCommentText("");
        setSelectedText("");
    }

    const deleteComment = async (indexOfCommentToBeRemoved) => {
        let comments = [...scan.comments];
        comments = comments.sort((a, b) => a.startIndex - b.startIndex);

        const documentRef = firestore.collection("content-scan").doc(contentScanId);

        const updatedComments = scan.comments.filter(comment => {
            return comment.relevantText !== comments[indexOfCommentToBeRemoved].relevantText;
        });

        await documentRef.update({ comments: updatedComments });

        setIndexOfHoveredSentence(null);
    }

    const editComment = async () => {
        let updatedComments = [...scan.comments];
        updatedComments = updatedComments.sort((a, b) => a.startIndex - b.startIndex);

        updatedComments[editedCommentIndex] = {
            ...updatedComments[editedCommentIndex],
            comment: newCommentText,
        }

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            comments: updatedComments,
        })

        setDisplayCommentForm(false);
        setEditedCommentIndex(null);
        setNewCommentText("");
        setSelectedText("");
    }

    useEffect(() => {
        const originalText = scan.text;
        let comments = [...scan.comments];

        //Highlight text of comment currently being added or edited
        if (displayCommentForm && selectedText.length > 0) {
            comments.push({
                comment: "",
                relevantText: selectedText,
                isCurrentHighlight: true,
                startIndex: startIndex,
                endIndex: endIndex
            });
        }

        // Sort comments by their 'startIndex' to ensure correct rendering order.
        comments = comments.sort((a, b) => a.startIndex - b.startIndex);

        // Now, when rendering, you account for the possible displacement caused by earlier comments
        let currentOffset = 0;
        const elements = comments.reduce((acc, comment, index) => {
            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, comment.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment itself
            acc.push(
                <mark
                    key={comment.startIndex}
                    data-comment={comment.comment}
                    data-index={index}
                    data-current-highlight={comment.isCurrentHighlight ? "true" : "false"}
                    onMouseEnter={() => setIndexOfHoveredSentence(index)}
                    onMouseLeave={() => setIndexOfHoveredSentence(null)}
                    className={comment.isCurrentHighlight ? style.markBlue : indexOfHoveredSentence == index ? style.markFocused : style.mark}
                >
                    {originalText.slice(comment.startIndex, comment.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = comment.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
    }, [scan, setIndexOfHoveredSentence, indexOfHoveredSentence, displayCommentForm, selectedText, startIndex, endIndex]);



    const handleOutsideClick = (event) => {
        if (!displayCommentForm && preRef.current && addCommentButtonRef.current && !preRef.current.contains(event.target) && !addCommentButtonRef.current.contains(event.target)) {
            handleTextSelection("");
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const isInSidebarView = useMediaQuery('(min-width: 1334px)');

    const TOP_OFFSET = isInSidebarView ? 230 : 280;

    function getSelectedTextCoordinates() {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();

            const top = rect.top + scrollPosition;
            setCommentFormRelativeY(top > TOP_OFFSET ? top - TOP_OFFSET : 0)
        }
    }

    //Add comments based of <mark> tags in text
    useEffect(() => {
        const newCommentElements = [];
        const commentPositions = [];

        if (preRef.current) {
            const marks = preRef.current.querySelectorAll('mark');

            marks.forEach((mark, index) => {
                const commentText = mark.getAttribute('data-comment');
                const markIndex = mark.getAttribute('data-index');
                const isCurrentHighlight = mark.getAttribute('data-current-highlight');
                const position = mark.getBoundingClientRect();

                if (isCurrentHighlight !== "true") {
                    // Determine initial top position
                    let topPosition = (position.top + scrollPosition) > TOP_OFFSET ? (position.top + scrollPosition) - TOP_OFFSET : 0;

                    // Create an off-screen div to measure the comment height
                    const offscreenDiv = document.createElement('div');
                    offscreenDiv.className = style.comment;
                    offscreenDiv.style.position = "absolute";
                    offscreenDiv.style.fontSize = "15px";
                    offscreenDiv.style.padding = " 0.25rem 0.5rem";
                    offscreenDiv.style.left = "-9999px";  // position off-screen
                    offscreenDiv.innerText = commentText;
                    document.body.appendChild(offscreenDiv);

                    // Measure the comment height
                    const commentHeight = offscreenDiv.offsetHeight;

                    // Remove the off-screen div
                    document.body.removeChild(offscreenDiv);

                    // Check for overlaps and adjust if necessary
                    for (const existingPosition of commentPositions) {
                        if ((topPosition >= existingPosition.start && topPosition <= existingPosition.end) ||
                            (topPosition + commentHeight >= existingPosition.start && topPosition <= existingPosition.end)) {
                            topPosition = existingPosition.end + 10;  // adjust by 5px gap
                        }
                    }

                    // Store the finalized position and height
                    commentPositions.push({ start: topPosition, end: topPosition + commentHeight });

                    // Create the JSX element for the comment
                    const commentElement = (
                        <Comment
                            commentText={commentText}
                            deleteComment={deleteComment}
                            indexOfHoveredSentence={indexOfHoveredSentence}
                            markIndex={markIndex}
                            topPosition={topPosition}
                            position={position}
                            setDisplayCommentForm={setDisplayCommentForm}
                            setIndexOfHoveredSentence={setIndexOfHoveredSentence}
                            setEditedCommentIndex={setEditedCommentIndex}
                            setNewCommentText={setNewCommentText}
                            setCommentFormRelativeY={setCommentFormRelativeY}
                        />
                    );

                    newCommentElements.push(commentElement);
                }

            });
        }

        setCommentElements(newCommentElements);
    }, [elements, indexOfHoveredSentence]);

    return (
        <div>
            <Flex justify={"space-between"}>
                <div/>
                {isInSidebarView && <ExportScan scan={scan} firebase={firebase} firestore={firestore} contentScanId={contentScanId} />}
            </Flex>
            <div className={style.wrapper}>
                <div>
                    {showGuide && <div className={style.guide}>
                        <span>{t('scan.comments.explaination')}</span>
                        <Button
                            variant="filled"
                            onClick={() => {
                                setShowGuide(false);
                                localStorage.setItem("hasSeenCommentGuide", "true")
                            }}
                        >
                            {t('scan.comments.explaination-confirmation')}
                        </Button>
                    </div>}

                    <div
                        id="text-container"
                        className={style.text}
                        ref={preRef}
                        style={{ whiteSpace: 'pre-line' }}
                        onMouseUp={() => handleTextSelection(window.getSelection())}
                    >
                        {elements}
                    </div>
                    <br />
                </div>

                <div className={style.sideWrapper} >
                    <div ref={addCommentButtonRef} className={style.addCommentWrapper} style={{ top: `${commentFormRelativeY}px` }}>
                        {displayCommentForm ?
                            <Card shadow="md" withBorder w={"20rem"}>
                                <Textarea
                                    autoFocus
                                    value={newCommentText}
                                    onChange={(e) => setNewCommentText(e.target.value)}
                                    autosize
                                    minRows={1}
                                    maxRows={10}
                                />
                                <Flex mt={"1rem"} columnGap={"0.5rem"}>
                                    <Button variant="outline" onClick={() => {
                                        setSelectedText("");
                                        setDisplayCommentForm(false);
                                        setNewCommentText("");
                                        setEditedCommentIndex(null)
                                    }}>{t('common.cancel')}</Button>
                                    <Button className="plausible-event-name=addComment" onClick={() => { editedCommentIndex ? editComment() : addComment() }}>{editedCommentIndex ? t('scan.comments.update') : t('scan.comments.comment')}</Button>
                                </Flex>
                            </Card>
                            :
                            <>
                                {selectedText && selectedText.length > 0 ? <button className={style.addIconButton} onClick={() => setDisplayCommentForm(true)}>
                                    <img className={style.addIcon} src={addCommentIcon} alt="Add comment"></img>
                                </button>
                                    : null}
                            </>
                        }
                    </div>

                    <div className={style.commentListWrapper}>
                        <div ref={commentsRef} className={style.commentList}>
                            {commentElements}
                        </div>
                    </div>
                </div>

                <GeneralFeedback scan={scan} contentScanId={contentScanId} firestore={firestore} />
            </div >
        </div>
    )
}
//#1c7ed6
export default CommentsTab;

const Comment = ({
    commentText,
    position,
    indexOfHoveredSentence,
    markIndex,
    topPosition,
    setIndexOfHoveredSentence,
    setEditedCommentIndex,
    setDisplayCommentForm,
    setNewCommentText,
    deleteComment,
    setCommentFormRelativeY
}) => {
    const { t } = useTranslation(['common']);

    const componentRef = useRef(null);

    const [optionsExpanded, setOptionsExpanded] = useState(false);

    //Handle cloick outside options menu
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (componentRef.current && !componentRef.current.contains(e.target)) {
                setOptionsExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [componentRef]);

    return (
        <div
            key={commentText + position.top}  // adjust this key as needed to ensure uniqueness
            className={indexOfHoveredSentence == markIndex ? style.commentFocused : style.comment}
            style={{
                position: "absolute",
                top: `${topPosition}px`,
            }}
            onMouseEnter={() => setIndexOfHoveredSentence(markIndex)}
            onMouseLeave={() => setIndexOfHoveredSentence(null)}
        >
            <span style={{ fontSize: "15px" }}>{commentText}</span>

            <div className={style.optionWrapper}>
                <button className={style.optionIconButton} onClick={() => optionsExpanded ? setOptionsExpanded(false) : setOptionsExpanded(true)}>
                    <img className={style.optionIcon} src={optionIcon} alt="options"></img>
                </button>
                {optionsExpanded && <div className={style.optionMenu} ref={componentRef}>
                    <button
                        onClick={() => {
                            setEditedCommentIndex(markIndex);
                            setDisplayCommentForm(true);
                            setOptionsExpanded(false);
                            setNewCommentText(commentText);
                            setCommentFormRelativeY(topPosition);
                        }}
                        className={style.optionMenuItem}>
                        {t('common.edit')}
                    </button>
                    <button onClick={() => { deleteComment(markIndex); setOptionsExpanded(false) }} className={style.optionMenuItem}>{t('common.delete')}</button>
                </div>}
            </div>
        </div>
    )
}