import style from './createFolder.module.css'
import folderIcon from './../../assets/folder.png';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CreateFolder = ({ auth, firestore, folderId, currentFolder, display, setDisplay }) => {
    const { t } = useTranslation(['common']);

    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false)
    const [folder, setFolder] = useState(currentFolder)

    const componentRef = useRef(null);

    const createFolder = async () => {
        const path = folderId && folder?.path ? `${folder.path}/${name.replace(" ", "-")}` : `${t('folders.main-folder')}/${name.replace(" ", "-")}`
        const foldersRef = firestore.collection("folders")
        await foldersRef.add({
            name: name,
            numberOfFolders: 0,
            numberOfScans: 0,
            uid: auth.currentUser.uid,
            timestampCreated: new Date(),
            timestampLastChanged: new Date(),
            parentId: folderId,
            path: path,
        })
        setName("")
        setDisplay(false)
    }

    useEffect(() => {
        // Add event listener to detect clicks outside of the component
        const handleOutsideClick = (e) => {
            if (componentRef.current && !componentRef.current.contains(e.target)) {
                setDisplay(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        // Cleanup the event listener when component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [componentRef]);

    useEffect(() => {
        setFolder(currentFolder)
    }, [currentFolder])

    if (display) return (
        <form className={style.listItem} ref={componentRef}>
            <div className={style.iconWrapper}><img className={style.icon} src={folderIcon} alt=""></img></div>
            <input className={style.input} type="text" value={name} onChange={(e) => setName(e.target.value)} autoFocus></input>
            <button className={style.createButton} type="submit" onClick={(e) => { setLoading(true); createFolder(); e.preventDefault() }}>{t('folders.create-folder.submit')}</button>
            <button className={style.cancelButton} onClick={(e) => { setName(""); setDisplay(false) }}>{t('common.cancel')}</button>
        </form>
    )

    return null
}

export default CreateFolder;