import { useTranslation } from "react-i18next";
import style from "./infoSlab.module.css"
import { createStyles, Progress, Box, Text, Group, SimpleGrid, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    progressLabel: {
        fontFamily: `Inter, ${theme.fontFamily}`,
        lineHeight: 1,
        fontSize: "16px",
        fontWeight: "500"
    },

    stat: {
        borderBottom: `${rem(3)} solid`,
        paddingBottom: rem(5),
    },

    statCount: {
        fontFamily: `Inter, ${theme.fontFamily}`,
        lineHeight: 1.3,
    },

    diff: {
        fontFamily: `Inter, ${theme.fontFamily}`,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },
}));



const InfoSlab = ({ scan, aiScore, displayExplainations }) => {
    const { t } = useTranslation(['common']);

    const { classes } = useStyles();

    const upperTreshold = scan?.version === "2.1" ? 0.9999 : 0.9995;


    // if (aiScore < 0.999) return null

    let probabilityOfCorrectEvaluationUnrounded = 0;
    if (aiScore >= upperTreshold) probabilityOfCorrectEvaluationUnrounded = 0.9999;

    //Regression model 0.9 < x < 0.9995
    const a = 0.9731173590718;
    const b = 34.3321921960834;

    if (aiScore >= 0.9 && aiScore < upperTreshold) probabilityOfCorrectEvaluationUnrounded = a * Math.pow(aiScore, b);

    const probabilityOfCorrectEvaluation = Math.round((probabilityOfCorrectEvaluationUnrounded) * 10000) / 100;

    const segments = [
        {
            value: 100 - probabilityOfCorrectEvaluation,
            color: "#0BB779",
            label: 100 - probabilityOfCorrectEvaluation > 20 ? `${Math.ceil((100 - probabilityOfCorrectEvaluation) * 10) / 10}%` : undefined,
        },
        {
            value: probabilityOfCorrectEvaluation,
            color: "#E22024",
            label: probabilityOfCorrectEvaluation > 20 ? `${Math.floor((probabilityOfCorrectEvaluation) * 10) / 10}%` : undefined,
        },
    ];

    const getAIScore = () => {
        return Math.round((Math.pow(((aiScore - 0.0001) * 100), 2) / 100) * 100) / 100
    }

    const getBorderColor = () => {
        if(aiScore >= 0.9995) return style.borderRed;
        if(aiScore >= 0.983) return style.borderYellow;
        return style.borderGreen
    }

    return (
        <div className={`${style.comment} ${getBorderColor()}`}>
            <Group position="apart">
                <Group align="flex-end" spacing="xs">
                    <Text fz="xl" fw={700}>
                        {aiScore >= upperTreshold ? t('scan.result.title.ai') : (aiScore >= 0.999 && scan.version !== "2.1") ? t('scan.result.title.sus') : aiScore >= 0.983 ? t('scan.result.title.unsure') : aiScore >= 0.9 ? t('scan.result.title.probably-original') : t('scan.result.title.original')}
                    </Text>
                </Group>
            </Group>

            {!displayExplainations && <div>
                <Text fz="sm">
                    {`${t('scan.result.description.intro')} ${getAIScore()}/100. ${t('scan.result.description.test-data')} ${Math.ceil((100 - probabilityOfCorrectEvaluation) * 10) / 10}% ${t('scan.result.description.original')} ${Math.floor((probabilityOfCorrectEvaluation) * 10) / 10}% ${t('scan.result.description.ai')}.`}
                </Text>
                {/* {aiScore >= 0.9 && aiScore < upperTreshold && <>
                    <br />
                    <Text fz="sm">
                        {`${t('scan.result.notice.intro')} ${Math.floor((probabilityOfCorrectEvaluation) * 10) / 10}% ${t('scan.result.notice.end')}.`}
                    </Text>
                </>} */}
            </div>}

            <Progress
                sections={segments}
                size={34}
                classNames={{ label: classes.progressLabel }}
                mt={40}
            />
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl">
                <Box key={"Orginal"} sx={{ borderBottomColor: "#0BB779" }} className={classes.stat}>
                    <Group position="apart" align="flex-end" spacing={0}>
                        <Text fw={600}>{t('scan.result.human')}</Text>
                        <Text c={"#0BB779"} fw={700} size="sm">
                            {Math.ceil((100 - probabilityOfCorrectEvaluation) * 10) / 10}%
                        </Text>
                    </Group>
                </Box>

                <Box key={"Kunstig intelligens"} sx={{ borderBottomColor: "#E22024" }} className={classes.stat}>
                    <Group position="apart" align="flex-end" spacing={0}>
                        <Text fw={600}>{t('scan.result.ai')}</Text>
                        <Text c={"#E22024"} fw={700} size="sm">
                            {Math.floor((probabilityOfCorrectEvaluation) * 10) / 10}%
                        </Text>
                    </Group>
                </Box>
            </SimpleGrid>
        </div>
    );
}

export default InfoSlab;
