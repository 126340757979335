export const handleFileUpload = async (event, files, setFiles, fileLimit) => {
    const uploadedFiles = event.target.files;

    const readFile = (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve({
                    file: file,
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    loading: true,
                });
            };
            fileReader.readAsDataURL(file);
        });
    };

    const processFiles = async () => {
        const newFiles = [];

        if (uploadedFiles.length <= fileLimit) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                const fileInfo = await readFile(file);
                newFiles.push(fileInfo);
            }
        }
        else {
            for (let i = 0; i < fileLimit; i++) {
                const file = uploadedFiles[i];
                const fileInfo = await readFile(file);
                newFiles.push(fileInfo);
            }
        }

        const alreadyUploadedFiles = [...files];

        setFiles(alreadyUploadedFiles.concat(newFiles));

        const processedFiles = newFiles.length > 0 ? await handleFiles(newFiles) : [];

        setFiles(alreadyUploadedFiles.concat(processedFiles))
    };

    processFiles();
};

const handleFiles = async (files) => {
    const responseArray = [...files];
    const apiPromises = [];

    files.forEach((file, index) => {
        const promise = getFileContent(file.file).then((data) => {
            responseArray[index] = {
                ...responseArray[index],
                text: data.text,
                success: data.success,
                loading: false,
            }
        }, (error) => {
            console.log(error.data)
        })
        apiPromises.push(promise)
    })

    await Promise.all(apiPromises)
    return responseArray;
}

const getFileContent = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch('https://europe-west1-documentextracter.cloudfunctions.net/extract-text', {
        method: 'POST',
        body: formData,
    })

    // Handle the response from the server
    if (response.ok) {
        const text = await response.json();
        return { text: text, success: true };
    } else {
        // toast.error(`${files.length > 1 ? "Noe gikk galt under opplasting med en eller flere av filene." : "Noe gikk galt under opplasting av filen."} Prøv på ny eller forsøk å lime inn teksten fra filen på "Sjekk Filter" siden.`, {
        //     duration: 7000,
        //     position: 'bottom-center',
        // })
        return { text: null, success: false }
    }
}