import firebase from 'firebase/compat/app';
import { Link, useNavigate } from 'react-router-dom';
import NavbarItem from '../../molecules/navbar-item/navbar-item';
import UserPreview from '../../molecules/user-preview/user-preview';
import home from './../../assets/home.png'
import list from './../../assets/folders.png'
import plus from './../../assets/plus.png'
import organizationIcon from './../../assets/organization.png'

import style from "./navbar.module.css"
import { useContext, useEffect } from 'react';
import { UserAndOrganzationContext } from '../../contexts/userAndOrganizationContext';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from '@mantine/core';
import useGetSubscription from '../../hooks/useGetSubscription';

const Navbar = () => {
    const { user, organization } = useContext(UserAndOrganzationContext);

    const { t } = useTranslation(['common']);

    const navigate = useNavigate();

    const auth = firebase.auth();

    const { subscriptionType, subscriptionName, subscriptionFromOrganization } = useGetSubscription();

    // const getSubscriptionName = () => {
    //     if(!user?.userData?.type) return t('navbar.flex');

    //     if(user.userData.type === "subscription0" || user.userData.type === "subscription0-trial7") return  t('navbar.premium');
    //     if(user.userData.type === "subscription1" || user.userData.type === "subscription1-trial7") return t('navbar.pro')
    //     if(user.userData.type === "subscription2" || user.userData.type === "subscription2-trial7") return  t('navbar.flex');
    // }

    useEffect(() => {}, [subscriptionType])

    return (
        <div className={style.wrapper}>
            <div className={style.contentArea}>
                <div className={style.leftWrapper}>
                    <Link to="/"><h3 style={{ margin: "0rem 0", marginRight: "2rem" }}>{t("navbar.logo")}</h3></Link>
                    <NavbarItem name={t('navbar.home')} url={""} icon={home} hideOnMobile={true} />
                    <NavbarItem name={`+ ${t('navbar.new-scan')}`} url={"new-scan"} icon={plus} />
                    <NavbarItem name={t('navbar.scans')} url={"scans"} icon={list} />
                    {window.innerWidth > 450 && organization.organizationData && <NavbarItem name={t('navbar.organization')} url={"organization"} icon={organizationIcon} />}
                    {auth.currentUser.uid === "lDfHwuprOZUhWVow5YYpnDtBk7A2" && <NavbarItem name={t('navbar.new-organization')} url={"new-organization"} icon={organizationIcon} />}
                </div>

                <div className={style.rightWrapper}>
                    {(!organization.organizationData) && window.innerWidth > 450 && <div>
                        {user?.userData?.type && (subscriptionType === "trial" || subscriptionType === "waiting" || subscriptionType === "cancelled") ?
                            <Button onClick={() => navigate("/upgrade")} color='dark' variant='outline' className={style.hideOnTabletOrSmaller}>{t('navbar.upgrade')}</Button>
                            :
                            <Badge
                                size='lg'
                                color='green'
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/upgrade")}
                                className={style.hideOnTabletOrSmaller}
                            >
                                {subscriptionName}
                            </Badge>
                        }
                    </div>}

                    <UserPreview user={auth.currentUser} onClick={() => navigate("/user")} />
                </div>
            </div>
        </div>
    )
}

export default Navbar;