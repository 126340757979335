import { Select, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const SelectOrganizationRole = ({ firebase, auth, organization, user }) => {
    const { t } = useTranslation(['common']);

    const isAdmin = organization.organizationData.admins.includes(user.userID)
    const [role, setRole] = useState(isAdmin ? "admin" : "member");


    const updateRoleQuery = async (addAdmin) => {
        const organizationRef = firebase.firestore().collection('organizations').doc(organization.organizationId);

        if (addAdmin) {
            await organizationRef.update({
                admins: firebase.firestore.FieldValue.arrayUnion(user.userID)
            });
        } else {
            await organizationRef.update({
                admins: firebase.firestore.FieldValue.arrayRemove(user.userID)
            });
        }

        window.location.href = "http://app.ekte.ai/organization"
    };

    useEffect(() => {
        if (isAdmin && role === "member") updateRoleQuery(false)
        else if (!isAdmin && role === "admin") updateRoleQuery(true)
    }, [role])

    const rolesData = [
        { value: 'member', label: t('organization.roles.member') },
        { value: 'admin', label: t('organization.roles.admin') },
    ]

    const viewerIsAdmin = organization.organizationData.admins.includes(auth.currentUser.uid)
    const viewerIsDisplayedUser = auth.currentUser.uid === user.userID;

    if (viewerIsDisplayedUser) return (
        <Text fz="xs" c="dimmed">
            {organization?.organizationData?.admins?.includes(user.userID) ? t('organization.roles.admin') : t('organization.roles.member')}
        </Text>
    )

    if (viewerIsAdmin) return (
        <Select value={role} data={rolesData} onChange={setRole} variant="unstyled" />
    )

    return (
        <Text fz="xs" c="dimmed">
            {organization?.organizationData?.admins?.includes(user.userID) ? t('organization.roles.admin') : t('organization.roles.member')}
        </Text>
    )
}

export default SelectOrganizationRole;