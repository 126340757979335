import { useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";
import toast from 'react-hot-toast';

import style from './delete-data.module.css'
import { useTranslation } from "react-i18next";

const DeleteData = ({ auth, firestore }) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['common']);

    const deleteAllData = async () => {

        let error = false
        const scansRef = firestore.collection("content-scan")
        const foldersRef = firestore.collection("folders")

        await scansRef.where("uid", "==", auth.currentUser.uid).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // scans.push({ id: doc.id, ...doc.data() })

                firestore.collection("content-scan").doc(doc.id).update({
                    text: null,
                    englishText: null,
                    paragraphs: null,
                    name: null,
                    feedbackParagraphs: null,
                    comments: null,
                    deleted: true,
                }).then(() => {

                }).catch((error) => {
                    error = true;
                });
            })
        }).catch((error) => {
            console.log(error)
        })

        await foldersRef.where("uid", "==", auth.currentUser.uid).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // scans.push({ id: doc.id, ...doc.data() })

                firestore.collection("folders").doc(doc.id).delete().then(() => {

                }).catch((error) => {
                    error = true;
                });
            })
        }).catch((error) => {
            console.log(error)
        })

        if (error) toast.error(t('user.administrate-data.toast.error'), {
            duration: 7000,
            position: 'bottom-center',
        })
        else toast.success(t('user.administrate-data.toast.success'), {
            duration: 4000,
            position: 'bottom-center',
        })

        navigate(-1)

    };

    return (
        <Page>
            <div className={style.wrapper}>
                <h3>{t('user.administrate-data.delete-scans-confirmation.title')}</h3>
                <p>{t('user.administrate-data.delete-scans-confirmation.description')}</p>
                <br/>
                <div className={style.options}>
                    <IconButton text={t('user.administrate-data.delete-scans-confirmation.confirm-button')} negative onClick={() => deleteAllData()} />
                    <IconButton text={t('common.cancel')} outlined onClick={() => navigate(-1)} />
                </div>
            </div>
        </Page>
    )
}

export default DeleteData;