import { useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from './unavailable.module.css'
import { useTranslation } from "react-i18next";

const Unavailable = () => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();

    return (
        <Page>
            <div className={style.wrapper}>
                <div className={style.topGrid}>
                    <h3>{t('new-scan.unavailable.title')}</h3>
                    <p>{t('new-scan.unavailable.description')}</p>
                </div>
                <div>
                    <br />
                    <IconButton text={t('new-scan.unavailable.try-again-button')} outlined onClick={() => navigate("/new-scan")} />
                </div>
            </div>
        </Page>
    )
}

export default Unavailable;