import GoogleButton from "react-google-button"
import IconButton from "../../molecules/IconButton/iconButton"
import Page from "../../molecules/page/page"
import { Link, useNavigate } from "react-router-dom"
import firebase from 'firebase/compat/app';

import style from './signInForm.module.css'
import { useState } from "react"
import { useTranslation } from "react-i18next";
import ResetPassword from "../reset-password/reset-password";

const SignInForm = ({ auth, nextPageUrl }) => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();

    const isMetaBrowser = navigator.userAgent.match(/FBAN|FBAV/i) //True if browser is Facebook or Messenger app browser

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState("")

    const signInWithGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        //auth.signInWithPopup(provider);


        try {
            const result = await auth.signInWithPopup(provider);
            // If sign-in is successful, navigate to the "/join" page
            if (result.user) {
                setPersistence()
                navigate(nextPageUrl)
            }
        } catch (error) {
            // Handle any errors that might occur during sign-in
            console.error('Error signing in with Google:', error.message);
        }

        // setPersistence()
        // navigate(nextPageUrl)
    }

    const signInWithEmail = () => {
        setError("")

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                //var user = userCredential.user;
                // ...
            })
            .catch((error) => {
                setErrorMessage(error)
            });

        setPersistence()
        navigate(nextPageUrl)
    }

    const setPersistence = async () => {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    }

    const setErrorMessage = (error) => {
        if (error.code === "auth/email-already-exists") setError(t('auth.errors.email-already-exists'))
        else if (error.code === "auth/user-not-found") setError(t('auth.errors.user-not-found'))
        else if (error.code === "auth/email-already-in-use") setError(t('auth.errors.email-already-exists'))
        else if (error.code === "auth/wrong-password") setError(t('auth.errors.wrong-password'))
        else if (error.code === "auth/weak-password") setError(t('auth.errors.weak-password'))
        else if (error.code === "auth/user-disabled") setError(t('auth.errors.user-disabled'))
        else setError(error.message)
    }

    const validateEmail = (email) => {
        // Split the email into two parts: the username and domain
        const emailParts = email.split('@');
        if (emailParts.length !== 2) {
            return false; // email should have only one @ symbol
        }

        const username = emailParts[0];
        const domain = emailParts[1];

        // Check if the domain has a valid format
        const domainParts = domain.split('.');
        if (domainParts.length < 2) {
            return false; // domain should have at least one dot
        }

        for (let i = 0; i < domainParts.length; i++) {
            if (domainParts[i].length < 2) {
                return false; // each domain part should have at least two characters
            }
        }

        // Check if the username has a valid format
        if (username.length < 1) {
            return false; // username should not be empty
        }

        return true;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && validateEmail(email)) {
            signInWithEmail()
        }
    }



    return (
        <div className={style.contentWrapper}>
            <h2>{t('auth.sign-in.form.title')}</h2>
            <Link to="/sign-up" ><p className={style.link}>{t('auth.sign-in.form.switch-to-create-user-link')}</p></Link>

            <div className={style.signInGroup}>
                <input autoFocus type="email" placeholder={t('auth.sign-in.form.email-label')} className="input" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown}></input>
                <input type="password" placeholder={t('auth.sign-in.form.password-label')} className="input" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}></input>
                <div className={style.signInSubmit}>
                    <p className={style.errorMessage}>{error}</p>
                    <IconButton className="plausible-event-name=Signin" primary text={`${t('auth.sign-in.form.submit')} →`} onClick={() => signInWithEmail()} disabled={!validateEmail(email) || password === ""} />
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <ResetPassword auth={auth} />
                </div>
            </div>

            <div className={style.contentGroup}>
                <div className={style.actionGroup}>
                    <div className={style.actionItem}>
                        <GoogleButton className="plausible-event-name=Signin" onClick={isMetaBrowser ? () => console.log("Not possible in Facebook or Messenger browser") : () => signInWithGoogle()} />
                        <br />
                        {isMetaBrowser && <span style={{ fontSize: "14px" }}>{t('auth.meta-browser-warning')}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInForm;