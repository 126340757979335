import { useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from './delete-user.module.css'
import { useTranslation } from "react-i18next";

const DeleteUser = ({ auth }) => {
    const navigate = useNavigate();
    const {t} = useTranslation(['common']);

    const deleteUser = () => {
        auth.currentUser.delete().then(() => {
            navigate("/")
        }).catch((error) => {
            console.log(error)
        });
    }

    return (
        <Page>
            <div className={style.wrapper}>
                <h3>{t('user.delete-user-confirmation.title')}</h3>
                <p>{t('user.delete-user-confirmation.description')}</p>
                <br/>
                <div className={style.options}>
                    <IconButton text={t('user.delete-user-confirmation.confirmation-button')} negative onClick={() => deleteUser()} />
                    <IconButton text={t('common.cancel')} outlined onClick={() => navigate(-1)} />
                </div>
            </div>
        </Page>
    )
}

export default DeleteUser;